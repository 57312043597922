<template>
  <div class="label-settings">
    <div class="label-format">
      <span>{{ $t('settings.print.label_format') }}</span>
      <div>
        <input id="pdf-format" type="radio" value="PDF" v-model="labelFormat" />
        <label for="pdf-format">
          {{ $t('settings.print.label_pdf') }}
        </label>
      </div>
      <div v-if="hasZpl">
        <input id="zpl-format" type="radio" value="ZPL" v-model="labelFormat" />
        <label for="zpl-format">
          {{ $t('settings.print.label_zpl') }}
        </label>
      </div>
    </div>
    <br />
    <div class="label-sizes">
      <span>{{ $t('settings.print.label_size') }}</span>
      <div>
        <select :value="modelValue" @change="(e) => $emit('update:modelValue', e.target.value)">
          <option v-for="size in labelSizes" :key="size" :value="size">
            {{ $t(`carrier.label_sizes.${size}`) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, watch, computed } from 'vue';

const props = defineProps({
  sizes: { type: Array, required: true },
  modelValue: { type: String }
});
const emits = defineEmits(['update:modelValue']);
const { sizes, modelValue } = toRefs(props);

const { labelFormat, hasZpl, onFormatChange, labelSizes } = useLabelSettings({
  sizes,
  modelValue,
  emits
});

// watch when the label format change to update the selected value
watch(labelFormat, onFormatChange);
</script>

<script>
const useLabelSettings = ({ sizes, modelValue, emits }) => {
  const isZPL = modelValue?.value?.startsWith('ZPL');
  const labelFormat = ref(isZPL ? 'ZPL' : 'PDF');

  const hasZpl = sizes.value.some((size) => size.startsWith('ZPL'));

  const labelSizes = computed(() => {
    return sizes.value.filter((size) => size.startsWith(labelFormat.value));
  });

  const onFormatChange = () => {
    const isInSelectedFormat = labelSizes.value.some((size) => size === modelValue.value);
    if (!isInSelectedFormat) {
      const firstValue = labelSizes.value[0];
      emits('update:modelValue', firstValue);
    }
  };

  return { labelFormat, hasZpl, onFormatChange, labelSizes };
};
</script>

<style scoped lang="scss"></style>
