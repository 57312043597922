<template>
  <div class="etsy-form">
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <div>
          <input
            type="checkbox"
            v-model="formFields.autofulfill"
            id="autofulfill"
            name="autofulfill"
          />
          <label for="autofulfill">{{ $t('forms.label.autofulfill', { shop: 'etsy' }) }} </label>
        </div>
        <p v-html="whitelistHtmlTags($t('shop.modal.sync.etsy.warning'))"></p>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import { useShopsStore } from '../../../stores/shops.js';
import { getRandomStr, toRFC4648, queryStringify, whitelistHtmlTags } from '../../../libs/utils';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import crypto from 'crypto';

const shopsStore = useShopsStore();
const props = defineProps({ shop: { type: Object } });
const { shop } = toRefs(props);
const isCreatedShop = Boolean(shop?.value?._id);

const { saveForm, editForm, formFields } = useForm({ shop, shopsStore, isCreatedShop });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, shopsStore, isCreatedShop }) => {
  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings } = shop.value;
      return {
        autofulfill: settings.autofulfill
      };
    }
    return {
      autofulfill: false
    };
  };
  const formFields = reactive(defaultFormFields());

  const getAuthData = () => {
    const state = getRandomStr();
    // Etsy want the base64 base on RFC4648
    const codeVerifier = toRFC4648(crypto.randomBytes(32).toString('base64'));
    const codeVerifierHash = crypto.createHash('sha256').update(codeVerifier).digest('base64');
    const codeChallenge = toRFC4648(codeVerifierHash);
    const redirectUri = `${document.location.origin}/shops/auth/etsy`;
    return { state, codeVerifier, codeChallenge, redirectUri };
  };

  const getAuthUrl = ({ state, codeChallenge, redirectUri }) => {
    const url = 'https://www.etsy.com/oauth/connect';
    const queryParams = {
      response_type: 'code',
      client_id: process.env.VUE_APP_ETSY_KEY,
      redirect_uri: redirectUri,
      scope:
        'address_r address_w billing_r cart_r cart_w email_r favorites_r favorites_w feedback_r ' +
        'listings_d listings_r listings_w profile_r profile_w recommend_r recommend_w shops_r ' +
        'shops_w transactions_r transactions_w',
      state,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    };
    const authUrl = `${url}?${queryStringify(queryParams)}`;
    return authUrl;
  };

  const saveForm = () => {
    const { state, codeVerifier, codeChallenge, redirectUri } = getAuthData();
    const authUrl = getAuthUrl({ state, codeChallenge, redirectUri });
    const forLocalStorage = { auth: { state, codeVerifier, redirectUri }, settings: formFields };
    localStorage.setItem('etsy', JSON.stringify(forLocalStorage));
    window.location.href = authUrl;
  };

  const editForm = async () => {
    const { autofulfill } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.etsy-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sync-setting {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p {
    color: red;
  }
}
</style>
