export default getTrackingUrl;

// lang is needed only for japanpost / yuupack and ninjavan
function getTrackingUrl({ tracking, carrierName, lang }) {
  // lang can be received as en-UK or ja-JP, in this case we just want the two letter code: en or ja
  const locale = lang.includes('-') ? lang.split('-')[0] : lang;
  switch (carrierName) {
    case 'japanpost':
      return `https://trackings.post.japanpost.jp/services/srv/search/?requestNo1=${tracking}&search.x=68&search.y=17&search=Tracking+start&locale=${locale}&startingUrlPatten=`;
    case 'fedex':
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking}`;
    case 'dhl':
      return `https://www.dhl.com/en/express/tracking.html?AWB=${tracking}&brand=DHL`;
    case 'ups':
      return `https://wwwapps.ups.com/etracking/tracking.cgi?InquiryNumber1=${tracking}&TypeOfInquiryNumber=T&AcceptUPSLicenseAgreement=yes&submit=Trac`;
    case 'yuupack':
      return `https://trackings.post.japanpost.jp/services/srv/search/?requestNo1=${tracking}&search.x=68&search.y=17&search=Tracking+start&locale=${locale}&startingUrlPatten=`;
    case 'yamato':
      return 'https://toi.kuronekoyamato.co.jp/cgi-bin/tneko';
    case 'sagawa':
      return `https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${tracking}`;
    case 'australiapost':
      return `https://auspost.com.au/track/${tracking}`;
    case 'colissimo':
      return `https://www.laposte.fr/particulier/outils/suivre-vos-envois?code=${tracking}`;
    case 'sfexpress':
      return `https://www.sf-express.com/cn/en/dynamic_function/waybill/#search/bill-number/${tracking}`;
    // TODO: when getTrackingUrl is call for ninjavan lang is specific to the ninjavan carrier
    //  see getNinjaCountry in v2 (to set / get outside of this function)
    // case 'ninjavan':
    // return `https://www.ninjavan.co/en-${locale}/tracking?id=${tracking}`;
    default:
      return undefined;
  }
}
