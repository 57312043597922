<template>
  <div class="editable-product">
    <div class="product-inputs">
      <input
        type="text"
        class="product-name"
        placeholder="e.g Totoro Bento"
        v-model="productToEdit.name"
      />
      <div class="product-price">
        <input type="number" class="price" v-model.number="productToEdit.price" />
        <span class="money-symbol">{{ moneyJson[order.meta.currency]?.symbol }} </span>
      </div>
      <span class="multiplier">x</span>
      <input type="number" class="product-qty" v-model.number="productToEdit.quantity" />
    </div>
    <div class="action-btns">
      <slot name="action-btns" :editedProduct="productToEdit"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import moneyJson from '../../../../common/json/money_format.json';

const props = defineProps({ order: { type: Object }, product: { type: Object } });
const { order } = toRefs(props);

const { productToEdit } = useProduct({ product: props.product });
</script>

<script>
const useProduct = ({ product }) => {
  const defaultProduct = {
    name: '',
    price: 0,
    quantity: 1
  };
  // when we edit a product the props.product already exist, but when we add a new product it don't
  // We want a copy of props.product as we don't want to modify the original product
  // because on cancel we want to keep the original product, not the edited one
  const productCopy = product && { ...product };
  const productToEdit = productCopy || ref(defaultProduct);
  return { productToEdit };
};
</script>

<style scoped lang="scss">
.editable-product {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .product-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;

    > input:not(:last-child) {
      margin-right: 15px;
    }

    .multiplier {
      margin-right: 15px;
    }

    .product-name {
      width: 160px;
    }
    .product-price {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 15px;

      > .price {
        width: 35px;
      }
      > .money-symbol {
        margin-left: 5px;
      }
    }
    .product-qty {
      width: 35px;
    }
  }

  .action-btns {
    display: flex;
    flex-direction: row;
    align-items: center;

    ::v-slotted(button):not(:last-child) {
      margin-right: 5px;
    }
  }
}

// remove arrows in input[type="number"]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
