<template>
  <select
    class="country-selector"
    v-bind="$attrs"
    :value="modelValue"
    @change="$emit('update:modelValue', $event.target.value)"
  >
    <option
      v-for="(countryName, countryCode) in countriesByCode"
      :value="countryCode"
      :key="countryCode"
    >
      {{ countryName }}
    </option>
  </select>
</template>

<script setup>
import { toRefs } from 'vue';
import countriesByCode from '../../../../common/json/countries_by_code.json';

const props = defineProps({ modelValue: String });
const { modelValue } = toRefs(props);
</script>

<script></script>

<style scoped lang="scss">
.country-selector {
  display: flex;
  width: 150px;
}
</style>
