<template>
  <div class="setting-parcels">
    <Alert type="error" v-for="error in toEditErrors" :key="error">{{
      error.message || $t(error.code)
    }}</Alert>
    <Table :items="parcelItems" :isEditable="true" @onRowClick="setParcelEditMode">
      <template v-slot:row="{ rowIndex: parcelIndex }">
        <EditableSettingParcel
          v-if="toEditIndex === parcelIndex"
          :parcel="getParcelToEdit(parcelIndex)"
          :settings="settings"
          @onSave="(editedParcel) => saveEditedParcel({ editedParcel, parcelIndex })"
          @onCancel="putBackInitialParcel"
        />
        <SettingParcel
          v-else
          :parcelValues="parcelItems[parcelIndex]"
          :isDefault="settings.custom_parcels[parcelIndex].default"
          @onEditParcel="setParcelEditMode(parcelIndex)"
          @onRemoveDefault="removeDefaultParcel(parcelIndex)"
          @onAddDefault="addDefaultParcel(parcelIndex)"
          @onDeleteParcel="deleteParcel(settings.custom_parcels[parcelIndex].name)"
        />
      </template>
      <template v-if="showAddParcel" v-slot:last-row>
        <EditableSettingParcel
          :settings="settings"
          @onSave="saveAddedParcel"
          @onCancel="cancelAddedParcel"
        />
      </template>
    </Table>
    <Alert type="error" v-for="error in addParcelErrors" :key="error">{{
      error.message || $t(error.code)
    }}</Alert>
    <div class="add-package">
      <button class="add-package-btn" @click="showAddParcel = true">
        ★ {{ $t('settings.packages.add') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, ref } from 'vue';
import Table from '../Shared/Table.vue';
import Alert from '../Shared/Alert.vue';
import EditableSettingParcel from './EditableSettingParcel.vue';
import SettingParcel from './SettingParcel.vue';
import { kyWithAuth } from '../../libs/ky';
import { convertFromGrams, convertDimension } from '../../libs/weight';
import useMeasurementUnit from '../../composables/useMeasurementUnit.js';

const props = defineProps({ settings: { type: Object } });
const { settings } = toRefs(props);

const { weightUnit, dimensionUnit } = useMeasurementUnit({ settings });
const { parcelItems, deleteParcel } = useParcels({ settings, weightUnit, dimensionUnit });
const { addDefaultParcel, removeDefaultParcel } = useDefaultParcel({ settings });
// eslint-disable-next-line vue/max-len
const { toEditIndex, toEditErrors, setParcelEditMode, getParcelToEdit, saveEditedParcel, putBackInitialParcel } = // prettier-ignore
  useEditParcel({
    settings
  });
const { showAddParcel, saveAddedParcel, cancelAddedParcel, addParcelErrors } = useAddParcel({
  settings
});
</script>

<script>
const useParcels = ({ settings, weightUnit, dimensionUnit }) => {
  const parcelDimension = (parcel) => {
    if (dimensionUnit.value === 'inch') {
      const convertedDepth = convertDimension({ value: parcel.depth, convertTo: 'inch' });
      const convertedWidth = convertDimension({ value: parcel.width, convertTo: 'inch' });
      const convertedHeight = convertDimension({ value: parcel.height, convertTo: 'inch' });
      const convertedDimension = `${convertedDepth} x ${convertedWidth} x ${convertedHeight} ${dimensionUnit.value}`; // eslint-disable-line max-len
      return convertedDimension;
    }
    const dimension = `${parcel.depth} x ${parcel.width} x ${parcel.height} ${dimensionUnit.value}`;
    return dimension;
  };

  const parcelWeight = (parcelWeight) => {
    if (parcelWeight) {
      const convertedWeight = convertFromGrams({ value: parcelWeight, unit: weightUnit.value });
      return `${convertedWeight} ${weightUnit.value}`;
    }
  };

  const parcelItems = computed(() => {
    return settings.value.custom_parcels.map((parcel) => {
      return {
        'ui.identifier': parcel.name,
        'ui.size': parcelDimension(parcel),
        'ui.weight': parcelWeight(parcel.weight)
      };
    });
  });

  const deleteParcel = async (parcelName) => {
    await kyWithAuth.delete(`settings/${settings.value._id}/custom_parcels/${parcelName}`);
    const toDeleteIndex = settings.value.custom_parcels.findIndex(
      (parcel) => parcel.name === parcelName
    );
    settings.value.custom_parcels.splice(toDeleteIndex, 1);
  };

  return {
    parcelItems,
    deleteParcel
  };
};

const useDefaultParcel = ({ settings }) => {
  const addDefaultParcel = (parcelIndex) => {
    const defaultParcel = settings.value.custom_parcels.find((parcel) => parcel.default === true);
    if (defaultParcel) {
      defaultParcel.default = false;
    }
    settings.value.custom_parcels[parcelIndex].default = true;
  };

  const removeDefaultParcel = (parcelIndex) => {
    settings.value.custom_parcels[parcelIndex].default = false;
  };

  return { addDefaultParcel, removeDefaultParcel };
};

const useEditParcel = ({ settings }) => {
  const toEditIndex = ref(-1);
  const toEditErrors = ref([]);

  const setParcelEditMode = (parcelIndex) => {
    toEditIndex.value = parcelIndex;
  };

  const getParcelToEdit = (parcelIndex) => {
    return { ...settings.value.custom_parcels[parcelIndex] };
  };

  const saveEditedParcel = async ({ editedParcel, parcelIndex }) => {
    try {
      await kyWithAuth.post('settings/custom_parcel/validate', { json: editedParcel });

      settings.value.custom_parcels.splice(parcelIndex, 1, editedParcel);
      // put back the parcel toEditIndex to -1 to exit edit mode
      toEditIndex.value = -1;
    } catch (err) {
      const { errors } = await err.response.json();
      toEditErrors.value = errors;
    }
  };

  const putBackInitialParcel = () => {
    toEditIndex.value = -1;
  };

  return {
    toEditIndex,
    toEditErrors,
    setParcelEditMode,
    getParcelToEdit,
    saveEditedParcel,
    putBackInitialParcel
  };
};

const useAddParcel = ({ settings }) => {
  const showAddParcel = ref(false);
  const addParcelErrors = ref([]);

  const saveAddedParcel = async (parcelToAdd) => {
    try {
      await kyWithAuth.post('settings/custom_parcel/validate', { json: parcelToAdd });

      showAddParcel.value = false;
      settings.value.custom_parcels.push(parcelToAdd);
    } catch (err) {
      const { errors } = await err.response.json();
      addParcelErrors.value = errors;
    }
  };

  const cancelAddedParcel = async () => {
    showAddParcel.value = false;
  };

  return { saveAddedParcel, cancelAddedParcel, showAddParcel, addParcelErrors };
};
</script>

<style scoped lang="scss">
.setting-parcels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.add-package {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

button {
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

.add-package-btn {
  height: 25px;
  border: 1px solid $brand-mint;
  color: $brand-mint;
}
</style>
