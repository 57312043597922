<template>
  <div class="orders-page">
    <img class="illustration" src="/svg/illustration_city.svg" alt="Illustration, City Landscape" />
    <header>
      <h2>{{ $t('orders.title') }}</h2>
      <section class="filters">
        <FormDropdown :values="['Shop', 'demo', 'acme', 'yolo']" />
        <FormDropdown :values="['Country', 'demo', 'acme', 'yolo']" />
        <FormDropdown :values="['Warehouse', 'demo', 'acme', 'yolo']" />
        <FormDropdown :values="['Tags', 'demo', 'acme', 'yolo']" />
      </section>
    </header>
    <OrderList :orders="sortedOrders" />
  </div>
</template>

<script setup>
import { useOrdersStore } from '../stores/orders.js';
import OrderList from '../components/Order/OrderList.vue';
import FormDropdown from '../components/Shared/Forms/FormDropdown.vue';

const ordersStore = useOrdersStore();
const sortedOrders = ordersStore.getSortedOrders;
</script>

<style scoped lang="scss">
.orders-page > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  h2 {
    width: 20%;
  }
}
.illustration {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  width: 50%;
}
.filters {
  display: flex;
  width: 100%;
  .form-dropdown {
    width: 100%;
  }
  > * + * {
    margin-left: 15px;
  }
}
</style>
