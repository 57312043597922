<template>
  <div class="carriers-page">
    <div class="carriers">
      <div v-for="carrierName in carriersNames" :key="carrierName">
        <div class="carrier-logo" @click="carrierToCreate = carrierName">
          <IconLibrary :name="carrierName" type="logo" />
        </div>
      </div>
      <CarrierModal
        v-if="carrierToCreate"
        :carrierName="carrierToCreate"
        @onClose="closeCreateCarrierForm"
      ></CarrierModal>
    </div>
    <div class="created-carriers">
      <div
        class="created-carrier"
        v-for="carrier in createdCarriers"
        @click="carrierToEdit = carrier"
        :key="carrier._id || getJpostKeyId(carrier)"
      >
        <IconLibrary :name="getCarrierName(carrier)" type="logo" />
        <span>{{ getCarrierName(carrier) }}</span>
        <span class="delete-carrier" @click="(e) => deleteCarrier({ carrier, e })">x</span>
      </div>
    </div>
    <CarrierModal
      v-if="carrierToEdit"
      :carrier="carrierToEdit"
      :carrierName="getCarrierName(carrierToEdit)"
      @onClose="closeEditCarrierForm"
    ></CarrierModal>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useCarriersStore } from '../stores/carriers.js';
import CarrierModal from '../components/Carriers/CarrierModal.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';

const carriersStore = useCarriersStore();

onMounted(async () => {
  await carriersStore.fetch();
});

const {
  carriersNames,
  carrierToCreate,
  carrierToEdit,
  createdCarriers,
  getCarrierName,
  deleteCarrier,
  closeCreateCarrierForm,
  closeEditCarrierForm
} = useCarriers({ carriersStore });
const { getJpostKeyId } = useJapanpost({ carriersStore });
</script>

<script>
const useJapanpost = ({ carriersStore }) => {
  const getJpostIds = (carrier) => {
    const jpostDomesticId = carrier?.japanpost?.japanpost_domestic?._id;
    const jpostIntnlId = carrier?.japanpost?.japanpost_international?._id;
    return { jpostDomesticId, jpostIntnlId };
  };

  // only use for the :key when iterating over carriers in vue template
  const getJpostKeyId = (carrier) => {
    const { jpostDomesticId, jpostIntnlId } = getJpostIds(carrier);
    return jpostDomesticId || jpostIntnlId;
  };

  const isJapanpost = (carrier) => {
    const carrierName = carrier?.meta?.type;
    return carrierName === 'japanpost_domestic' || carrierName === 'japanpost_international';
  };

  const deleteJpostCarrier = async (carrier) => {
    const { jpostDomesticId, jpostIntnlId } = getJpostIds(carrier);
    if (jpostDomesticId) {
      await carriersStore.delete({ carrierId: jpostDomesticId });
    }
    if (jpostIntnlId) {
      await carriersStore.delete({ carrierId: jpostIntnlId });
    }
  };

  // special case for japanpost as in the UI we have one carriers, but in the db / backend we have two carriers
  // so we link the international and domestic carriers into a single japanpost carrier.
  // for now we does not support multi carriers for japanpost, if one day It's the case this function would need to be edited
  const linkJapanpostCarriers = (carriers) => {
    const carriersWithJpost = carriers.reduce((acc, carrier) => {
      if (isJapanpost(carrier)) {
        const jpostCarrier = acc.find((carrier) => carrier.japanpost);
        // if japanpost carrier already exist, add the second one to it
        if (jpostCarrier?.japanpost) {
          jpostCarrier.japanpost[carrier.meta.type] = carrier;
          return acc;
        }
        return [...acc, { japanpost: { [carrier.meta.type]: carrier } }];
      }
      return [...acc, carrier];
    }, []);
    return carriersWithJpost;
  };

  return { deleteJpostCarrier, linkJapanpostCarriers, getJpostKeyId };
};

const useCarriers = ({ carriersStore }) => {
  const carriersNames = ['japanpost', 'fedex', 'dhl', 'ups', 'yamato', 'sagawa', 'pegasus'];
  const carrierToCreate = ref(null); // carrier name
  const carrierToEdit = ref(null); // carrier object
  const { deleteJpostCarrier, linkJapanpostCarriers } = useJapanpost({ carriersStore });

  const deleteCarrier = async ({ carrier, e }) => {
    e.stopPropagation();
    //  special case for japanpost, as we have only one carrier in the UI, but two carriers in the db
    if (carrier?.japanpost) {
      await deleteJpostCarrier(carrier);
    } else {
      await carriersStore.delete({ carrierId: carrier._id });
    }
  };

  const closeCreateCarrierForm = () => {
    carrierToCreate.value = null;
  };

  const closeEditCarrierForm = () => {
    carrierToEdit.value = null;
  };

  const getCarrierName = (carrier) => {
    // exception for japanpost as it has two carriers (intnl / domestic)
    const carrierName = carrier?.japanpost ? 'japanpost' : carrier?.meta?.type;
    return carrierName;
  };

  const createdCarriers = computed(() => {
    const withJpost = linkJapanpostCarriers(carriersStore.carriers);
    return withJpost;
  });

  return {
    carrierToCreate,
    carrierToEdit,
    carriersNames,
    getCarrierName,
    createdCarriers,
    deleteCarrier,
    closeCreateCarrierForm,
    closeEditCarrierForm
  };
};
</script>

<style scoped lang="scss">
.carriers-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carriers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 25px;
  }
}

.created-carriers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.created-carrier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: dotted 1px black;
  padding: 10px;
  cursor: pointer;
}

.carrier-logo {
  cursor: pointer;
  display: flex;
}

.delete-carrier {
  cursor: pointer;
  border: 1px solid red;
  color: $brand-corail;
  padding: 2px;
  margin-left: 5px;
}
</style>
