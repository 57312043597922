<template>
  <div class="products">
    <div class="unfulfilled">
      <h3 class="title">{{ $t('order.unfulfilled_products.title') }}</h3>
      <div class="actions-buttons">
        <button class="add-product-btn" @click="showAddProduct = true">+ {{ $t('ui.add') }}</button>
      </div>
      <Alert type="error" v-for="error in editProductErrors" :key="error">{{
        error.message || $t(error.code)
      }}</Alert>
      <Alert type="error" v-for="error in addProductErrors" :key="error">{{
        error.message || $t(error.code)
      }}</Alert>
      <template v-for="product in unfulfilledProducts" :key="product.id">
        <EditableProduct v-if="product.id === productToEditId" :order="order" :product="product">
          <template v-slot:action-btns="{ editedProduct }">
            <button class="add-product-btn" @click="saveEditedProduct(editedProduct)">
              {{ $t('ui.save') }}
            </button>
            <button class="cancel-product-btn" @click="productToEditId = null">⊘</button>
          </template>
        </EditableProduct>
        <Product
          v-else
          :hasCheckbox="true"
          @onProductSelect="selectProduct(product)"
          :product="product"
          :order="order"
        >
          <template v-slot:action-btn>
            <button class="edit-product-btn" @click="productToEditId = product.id">✎</button>
            <button class="remove-product-btn" @click="removeProduct(product)">x</button>
          </template>
        </Product>
      </template>
      <EditableProduct v-if="showAddProduct" :order="order">
        <template v-slot:action-btns="{ editedProduct }">
          <button class="add-product-btn" @click="saveAddedProduct(editedProduct)">
            {{ $t('ui.save') }}
          </button>
          <button class="cancel-product-btn" @click="showAddProduct = false">⊘</button>
        </template>
      </EditableProduct>
    </div>
    <div v-if="fulfilledProducts?.length > 0" class="fulfilled">
      <h3 class="title">{{ $t('order.fulfilled_products.title') }}</h3>
      <Product
        v-for="product in fulfilledProducts"
        :key="product.name"
        :product="product"
        :order="order"
      />
    </div>
    <div v-if="removedProducts?.length > 0" class="removed">
      <h3 class="title">{{ $t('order.removed_products.title') }}</h3>
      <Product
        v-for="product in removedProducts"
        :key="product.name"
        :product="product"
        :order="order"
      >
        <template v-slot:action-btn>
          <button class="put-back-product-btn" @click="putBackProduct(product)">↺</button>
        </template>
      </Product>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, ref } from 'vue';
import { getRandomStr } from '../../libs/utils';
import Product from './Product.vue';
import EditableProduct from './EditableProduct.vue';
import Alert from '../Shared/Alert.vue';
import { kyWithAuth } from '../../libs/ky';

const props = defineProps({
  order: { type: Object, required: true }
});
const { order } = toRefs(props);
const { products } = order.value;

const { fulfilledProducts, unfulfilledProducts, removedProducts } = useProducts({ products });
const { selectProduct, removeProduct, putBackProduct } = useProduct();
const { showAddProduct, saveAddedProduct, addProductErrors } = useAddProduct({ products });
const { productToEditId, saveEditedProduct, editProductErrors } = useEditProduct({ products });
</script>

<script>
const useProducts = ({ products }) => {
  const fulfilledProducts = computed(() =>
    products.filter((product) => product.state === 'fulfilled')
  );
  const unfulfilledProducts = computed(() =>
    products.filter((product) => product.state === 'unfulfilled')
  );
  const removedProducts = computed(() => products.filter((product) => product.state === 'removed'));

  return { fulfilledProducts, unfulfilledProducts, removedProducts };
};

const useProduct = () => {
  const selectProduct = (product) => {
    product.selected = !product.selected;
  };

  const removeProduct = (product) => {
    product.state = 'removed';
    product.selected = false;
  };

  const putBackProduct = (product) => {
    product.state = 'unfulfilled';
    product.selected = true;
  };

  return { selectProduct, removeProduct, putBackProduct };
};

const useAddProduct = ({ products }) => {
  const showAddProduct = ref(false);
  const addProductErrors = ref([]);

  const saveAddedProduct = async (product) => {
    try {
      const productToAdd = {
        ...product,
        state: 'unfulfilled',
        selected: true,
        id: getRandomStr()
      };
      await kyWithAuth.post('orders/product/validate', { json: productToAdd });
      products.push(productToAdd);
      showAddProduct.value = false;
    } catch (err) {
      const { errors } = await err.response.json();
      addProductErrors.value = errors;
    }
  };

  return { showAddProduct, saveAddedProduct, addProductErrors };
};

const useEditProduct = ({ products }) => {
  const productToEditId = ref(null);
  const editProductErrors = ref([]);

  const saveEditedProduct = async (editedProduct) => {
    try {
      await kyWithAuth.post('orders/product/validate', { json: editedProduct });
      const toEditIndex = products.findIndex((product) => product.id === editedProduct.id);
      products.splice(toEditIndex, 1, editedProduct);
      productToEditId.value = null;
    } catch (err) {
      const { errors } = await err.response.json();
      editProductErrors.value = errors;
    }
  };

  return { productToEditId, editProductErrors, saveEditedProduct };
};
</script>

<style scoped lang="scss">
.products {
  display: flex;
  flex-direction: column;
}

.unfulfilled,
.fulfilled,
.removed {
  display: flex;
  flex-direction: column;
  width: 450px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 15px;

  & > .title {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.unfulfilled {
  .actions-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.remove-product-btn,
.cancel-product-btn {
  background: white;
  border: 1px solid $brand-corail;
  border-radius: 5px;
  height: 22px;
  color: $brand-corail;
  cursor: pointer;
}

// .cancel-product-btn {
//   background: white;
//   border: none;
//   color: $brand-corail;
//   cursor: pointer;
//   font-size: x-large;
// }

.put-back-product-btn,
.edit-product-btn,
.add-product-btn {
  background: white;
  border: 1px solid $brand-mint;
  height: 22px;
  border-radius: 5px;
  color: $brand-mint;
  cursor: pointer;
}
</style>
