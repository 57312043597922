export { useUserStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useUserStore = defineStore('user', {
  state: () => {
    return {
      userId: null,
      isMailVerified: false,
      isLogged: false
    };
  },
  actions: {
    async auth() {
      try {
        const { userId } = await kyWithAuth.get('users/is-authenticated').json();
        this.userId = userId;
        this.isLogged = true;
      } catch (err) {
        this.userId = null;
        this.isLogged = false;
      }
    },
    async login({ email, password }) {
      const json = { login: email, password: password };
      const { jwt } = await kyWithAuth.post('users/login', { json }).json();
      window.localStorage.setItem('jwt', jwt);
      this.isLogged = true;
    },
    async verifyMail({ email }) {
      const json = { email };
      try {
        const { jwt } = await kyWithAuth.patch('users/verify-mail', { json }).json();
        if (jwt) {
          window.localStorage.setItem('jwt', jwt);
          this.isMailVerified = true;
          this.isLogged = true;
        }
      } catch {
        this.isMailVerified = false;
        this.isLogged = false;
      }
    }
  }
});
