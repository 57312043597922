<template>
  <button class="create-button">+ {{ $t(title) }}</button>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({ title: { type: String } });

const { title } = toRefs(props);
</script>

<style scoped lang="scss">
.create-button {
  background: white;
  cursor: pointer;
  width: 200px;
  height: 28px;
  border: 1px solid $brand-mint;
  color: $brand-mint;
  border-radius: 15px;
  margin-bottom: 15px;
}
</style>
