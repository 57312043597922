<template>
  <div class="magento-form">
    <IntegrationCard v-if="isCreatedShop">
      <span className="created-shop-url">{{ formFields.shopIdentifier }}</span>
    </IntegrationCard>
    <IntegrationCard
      v-else
      :title="$t('shop.modal.credentials.title')"
      class="credentials-settings"
    >
      <div class="credential-setting">
        <label for="shop_url">{{ $t('shops.magento.add.shop_url') }} </label>
        <input
          type="text"
          v-model="formFields.shop_url"
          :placeholder="$t('shops.magento.add.shop_url_placeholder')"
          id="shop_url"
          name="shop_url"
        />
      </div>
      <div class="credential-setting">
        <label for="access_token">{{ $t('forms.label.token') }} </label>
        <input
          type="password"
          v-model="formFields.access_token"
          :placeholder="$t('forms.placeholder.token')"
          id="access_token"
          name="access_token"
        />
      </div>
    </IntegrationCard>
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.autofulfill"
          id="autofulfill"
          name="autofulfill"
        />
        <label for="autofulfill">{{ $t('forms.label.autofulfill', { shop: 'magento' }) }} </label>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import { useShopsStore } from '../../../stores/shops.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';

const shopsStore = useShopsStore();
const props = defineProps({ shop: { type: Object } });
const { shop } = toRefs(props);
const isCreatedShop = Boolean(shop?.value?._id);

const { saveForm, editForm, formFields } = useForm({ shop, shopsStore, isCreatedShop });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, shopsStore, isCreatedShop }) => {
  const saveForm = async () => {
    const requestBody = {
      settings: {
        autofulfill: formFields.autofulfill
      },
      credentials: {
        shop_url: formFields.shop_url,
        access_token: formFields.access_token
      }
    };
    await shopsStore.create({ shopType: 'magento', requestBody });
  };

  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings, meta } = shop.value;
      return {
        autofulfill: settings.autofulfill,
        shopIdentifier: meta.identifier
      };
    }
    return {
      autofulfill: false
    };
  };
  const formFields = reactive(defaultFormFields());

  const editForm = async () => {
    const { autofulfill } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.magento-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sync-setting {
  display: flex;
  align-items: center;
}

.credential-setting {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  input {
    width: 70%;
  }
}

.created-shop-url {
  font-weight: bold;
  font-size: large;
}
</style>
