<template>
  <div class="form-input">
    <label :for="title">{{ $t(title) }}</label>
    <input
      :id="title"
      :name="title"
      :type="type"
      v-bind="$attrs"
      :value="modelValue"
      :placeholder="
        type === 'email'
          ? $t(placeholder, { address: 'myemail', domain: 'address.com' })
          : $t(placeholder)
      "
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script setup>
defineProps({
  title: { type: String },
  type: { type: String, default: 'text' },
  placeholder: { type: String, default: 'Insert value here' },
  modelValue: { type: String }
});
</script>

<script>
export default {
  name: 'FormInput',
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  width: 100%;
  > input {
    width: 100%;
    padding: 10px;
    border-radius: $base-radius;
    border: 1.5px solid;
    border-color: $brand-light-indigo;
    color: $brand-deep-purple;
    transition: border-color 250ms ease;
    &:focus {
      outline: none;
      border-color: $brand-deep-purple;
    }
  }
  > label {
    margin-bottom: 6px;
  }
}
</style>
