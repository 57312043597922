<template>
  <div class="receiver-address">
    <div class="header">
      <h3>{{ $t('order.receiver.title') }}</h3>
      <button @click="showAddressModal = true">{{ $t('ui.edit') }}</button>
      <AddressModal
        v-if="showAddressModal"
        :address="{ ...order.to_address }"
        :autofulfillAddresses="addresses"
        :errors="receiverAddrErrors"
        @onCloseModal="onCloseModal"
        @onSaveAddress="onSaveAddress"
      ></AddressModal>
    </div>
    <div class="address">
      <div>
        {{ countriesByCode[order.to_address.country] }}
      </div>
      <div>
        {{ order.to_address.address1 }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref } from 'vue';
import { kyWithAuth } from '../../libs/ky';
import AddressModal from '../Shared/Address/AddressModal.vue';
import countriesByCode from '../../../../common/json/countries_by_code.json';
import { cleanObj } from '../../../../common/libs/utils';

const emits = defineEmits(['onSaveAddress']);
const props = defineProps({ order: { type: Object }, addresses: { type: Array } });
const { addresses } = toRefs(props);

const { showAddressModal, onSaveAddress, receiverAddrErrors, onCloseModal } = useAddress({ emits });
</script>

<script>
const useAddress = ({ emits }) => {
  const showAddressModal = ref(false);
  const receiverAddrErrors = ref([]);

  const onSaveAddress = async (address) => {
    try {
      await kyWithAuth.post(`addresses/validate`, { json: cleanObj(address) });
      emits('onSaveAddress', address);
      showAddressModal.value = false;
      receiverAddrErrors.value = [];
    } catch (err) {
      const { errors } = await err.response.json();
      receiverAddrErrors.value = errors;
    }
  };

  const onCloseModal = () => {
    showAddressModal.value = false;
    receiverAddrErrors.value = [];
  };

  return { showAddressModal, receiverAddrErrors, onSaveAddress, onCloseModal };
};
</script>

<style scoped lang="scss">
.receiver-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > button {
      background-color: white;
      margin-left: 8px;
      color: $brand-mint;
      cursor: pointer;
      height: 20px;
      border: 1px solid $brand-mint;
    }
  }

  > .address {
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
