<template>
  <div class="invited-team-user">
    <h3>Invited team user</h3>
    <div class="new-password">
      <label for="new-password">Enter your password: </label>
      <input type="password" name="new-password" v-model="newPassword" />
    </div>
    <button @click="confirmInvitedUser">confirm</button>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { kyWithAuth } from '../libs/ky';

const newPassword = ref('');

const route = useRoute();
const router = useRouter();
const { setJwt, confirmInvitedUser } = useInvitedUser({ route, router, newPassword });

onMounted(() => {
  setJwt();
});
</script>

<script>
const useInvitedUser = ({ route, router, newPassword }) => {
  // jwt is in the query params when the invited team user is redirected from the invitation link
  const setJwt = () => {
    if (route.query.jwt) {
      window.localStorage.setItem('jwt', route.query.jwt);
      router.replace({ query: null }); // once jwt is set in the local storage, we don't need to keep it in the url
    }
  };

  const confirmInvitedUser = async () => {
    const json = { password: newPassword.value };
    const { jwt } = await kyWithAuth.post('teams/verify-invited-user', { json }).json();
    localStorage.setItem('jwt', jwt);
    router.push({ name: 'Teams' });
  };

  return { setJwt, confirmInvitedUser };
};
</script>

<style scoped lang="scss">
.invited-team-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-password {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  > label {
    margin-right: 10px;
  }

  > input {
    width: 180px;
  }
}
</style>
