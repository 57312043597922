<template>
  <main>
    <section>
      <img class="logo" src="/logos/shipco-black.svg" alt="Ship&co Logo" />
      <h1>{{ $t('login.title') }}</h1>
      <form @submit.prevent="authenticate">
        <FormInput
          title="forms.label.email"
          type="email"
          placeholder="forms.placeholder.mail"
          v-model="email"
        />
        <FormInput title="forms.label.password" type="password" v-model="password" />
        <FormButton title="forms.label.signin" />
        <ul v-if="loginErrors" class="errors">
          <li v-for="error in loginErrors" :key="error.code">{{ error.message }}</li>
        </ul>
      </form>
    </section>
    <section class="img-container">
      <span class="badge version">
        {{ $t('ui.version') }}
        <strong>{{ version }}</strong>
      </span>
    </section>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { version } from '../../package';
import { useUserStore } from '../stores/user';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormButton from '../components/Shared/Forms/FormButton.vue';

const userStore = useUserStore();
const email = ref('');
const password = ref('');
const loginErrors = ref([]);

const router = useRouter();
const { authenticate } = useLogin({ email, password, loginErrors, router, userStore });
</script>

<script>
const useLogin = ({ email, password, loginErrors, router, userStore }) => {
  const authenticate = async () => {
    try {
      await userStore.login({ email: email.value, password: password.value });
      router.push({ name: 'Home' });
    } catch (err) {
      const { errors } = await err.response.json();
      loginErrors.value = errors;
    }
  };
  return { authenticate };
};
</script>

<style scoped lang="scss">
main {
  display: flex;
  min-height: 100%;
}
h1 {
  margin: 15px 0 65px;
}
.logo {
  width: 130px;
}
.badge {
  border-radius: 20px;
  background-color: rgba($white, 0.6);
  color: $brand-dark-purple;
  border: 1px solid $brand-dark-purple;
  padding: 3px 18px;
  font-size: 9px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  text-transform: uppercase;
}
main > section:not(.img-container) {
  padding: 50px 80px;
  width: 700px;
  background-image: url('../../public/svg/illustration_city.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  background-color: #fbfcfd;
}
main > .img-container {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-image: url('../../public/svg/illustration_login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-color: $white;
  box-shadow: -5px 0px 10px 0px rgba($black, 0.1);
  position: relative;
}

// @media (min-width: 1200px) {
//   main > .img-container {
//     background-size: 80%;
//   }
// }

@media (min-width: 1440px) {
  main > .img-container {
    background-size: 70%;
  }
}
</style>
