export { useOrdersStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';
import { queryStringify } from '../libs/utils.js';

const useOrdersStore = defineStore('orders', {
  state: () => {
    return {
      count: 0,
      orders: [],
      order: {}
    };
  },
  getters: {
    getSortedOrders: (state) => {
      return state.orders.sort((a, b) => {
        return new Date(b?.meta?.created_at) - new Date(a?.meta?.created_at);
      });
    }
  },
  actions: {
    async fetch() {
      //Todo Add Params ( Search / Sort / Filter, etc... )
      const projection = [
        '_id',
        'meta.identifier',
        'meta.created_at',
        'meta.type',
        'meta.state',
        'to_address.country',
        'to_address.full_name',
        'origin.financial_state'
      ];
      const searchParams = queryStringify({ fields: projection });
      const { orders = [] } = await kyWithAuth.get('orders', { searchParams }).json();
      this.orders.splice(0, this.orders.length);
      this.orders.push(...orders);
      return this.orders;
    },
    async fetchOne(orderId) {
      const res = await kyWithAuth.get(`orders/${orderId}`).json();
      return (this.order = res.order);
    },
    async fetchCount() {
      //TODO: Use Aggregation to have more performant way to count/paginate
      const { count } = await kyWithAuth.get('orders/count').json();
      return (this.count = count);
    },
    addOrder(order) {
      if (this.orders.length === 50) this.orders.splice(-1);
      this.orders.unshift(order);
      this.count++;
    }
  }
});
