<template>
  <div class="main-title">
    <input type="checkbox" id="enable-jpost-intnl" v-model="wantJpostIntnl" />
    <h2>Japanpost International</h2>
  </div>
  <div :class="!wantJpostIntnl ? 'disable-settings' : ''">
    <div class="method-settings">
      <h3>{{ $t('carriers.modal.methods.title') }}</h3>
      <IntegrationCheckboxes v-model="formFields.methodSettings" :checkboxes="japanpostServices" />
    </div>
    <div>
      <h3>{{ $t('carriers.japanpost.shipments_preferences') }}</h3>
      <input
        type="checkbox"
        id="weight_label"
        name="weight_label"
        v-model="formFields.weightLabel"
      />
      <label for="weight_label">{{ $t('carriers.japanpost.weight_label') }}</label>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs } from 'vue';
import { useCarriersStore } from '../../../../stores/carriers.js';
import IntegrationCheckboxes from '../../../Shared/Integration/IntegrationCheckboxes.vue';
import japanpostServices from '../../../../../../common/json/japanpost/services.json';

const props = defineProps({ carrier: Object, customerNumbers: Array });
const { carrier, customerNumbers } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, formFields, wantJpostIntnl } = useForm({
  carriersStore,
  carrier,
  isCreatedCarrier,
  customerNumbers
});

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in JapanpostForm.vue
</script>

<script>
const useForm = ({ carriersStore, carrier, isCreatedCarrier, customerNumbers }) => {
  // use to disable the japanpost intnl form or not
  const wantJpostIntnl = ref(false);

  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { settings } = carrier.value;
      wantJpostIntnl.value = true;
      return {
        methodSettings: settings?.methods,
        weightLabel: settings?.weight_label
      };
    }
    return {
      methodSettings: ['ems']
    };
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const carrierForm = {
      carrier: 'japanpost_international',
      credentials: {
        account_name: 'Kyoto',
        customer_numbers: customerNumbers?.value
      },
      settings: {
        methods: formFields.methodSettings,
        weight_label: formFields.weightLabel
      }
    };
    if (carrierForm.credentials.customer_numbers?.join('')?.length === 0) {
      delete carrierForm.credentials.customer_numbers; // do not include it to not provocate schema validation
    }
    if (wantJpostIntnl.value) {
      await carriersStore.create({ carrierForm });
      return true; // to know if successfully save form or not
    }
  };

  const editForm = async () => {
    const carrierId = carrier?.value?._id;
    // two following cases are specific to japanpost (due to two carriers on the same form),
    // as we have to delete or add the carrier through the edit form
    const wantToDelete = isCreatedCarrier && !wantJpostIntnl.value;
    const wantToCreate = !isCreatedCarrier && wantJpostIntnl.value;
    const wantToEdit = isCreatedCarrier && wantJpostIntnl.value;
    if (wantToDelete) {
      await carriersStore.delete({ carrierId });
    } else if (wantToCreate) {
      await saveForm();
    } else if (wantToEdit) {
      const toUpdate = {
        carrier: 'japanpost_international',
        credentials: {
          customer_numbers: customerNumbers?.value
        },
        settings: {
          methods: formFields.methodSettings,
          weight_label: formFields.weightLabel
        }
      };

      if (toUpdate.credentials.customer_numbers?.join('')?.length === 0) {
        // put to null, in order to delete the customer_numbers field (as is nullable, see japanpost_domestic/schema.js)
        // this only happen if user already previously saved a customer_numbers and now want to remove it
        toUpdate.credentials.customer_numbers = null;
      }
      await carriersStore.update({ carrierId, body: toUpdate });
    }
  };

  return { saveForm, editForm, formFields, wantJpostIntnl };
};
</script>

<style scoped lang="scss">
.main-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  > #enable-jpost-intnl {
    margin-right: 15px;
  }
}

.disable-settings {
  pointer-events: none;
  opacity: 0.2;
}

.method-settings {
  > h3 {
    margin-top: 0px;
  }
}
</style>
