<template>
  <div class="icon-wrapper" :class="[`svg-size-${this.size}`, { 'svg-margin': hasMargin }]">
    <svg
      :class="[`svg-${this.type}`, `svg-${this.type}--${this.name}`, { 'svg-bold': isBold }]"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use :xlink:href="path" xmlns:xlink="http://www.w3.org/1999/xlink" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'icon-library',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'icon'
    },
    hasMargin: {
      type: Boolean,
      default: true
    },
    isBold: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'base',
      validator(value) {
        return ['xs', 'sm', 'base', 'lg', 'xl'].includes(value);
      }
    }
  },
  computed: {
    path() {
      return this.type === 'icon'
        ? `/svg/icons-sprite.svg#icon-${this.name}`
        : `/logos/logos-sprite.svg#icon-${this.name}`;
    }
  }
};
</script>

<style lang="scss">
.svg-icon,
.svg-logo {
  display: inline-block;
}
.svg-icon {
  stroke-width: 1.5;
  stroke: currentColor;
  stroke-linecap: round;
  fill: transparent;
}
.svg-bold {
  stroke-width: 3;
}
svg {
  width: 100%;
  height: 100%;
}
.icon-wrapper {
  &.svg-margin {
    margin-right: 6px;
  }
  &.svg-size-xs {
    width: 16px;
    height: 16px;
  }
  &.svg-size-sm {
    width: 24px;
    height: 24px;
  }
  &.svg-size-base {
    width: 32px;
    height: 32px;
  }
  &.svg-size-lg {
    width: 40px;
    height: 40px;
  }
  &.svg-size-xl {
    width: 58px;
    height: 58px;
  }
}
</style>
