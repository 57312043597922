<template>
  <div :class="`alert-${type}`" v-if="displayAlert">
    <div class="alert-msg">
      <slot></slot>
    </div>
    <div class="close-alert">
      <button class="close-alert-btn" @click="onCloseClick">x</button>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator(value) {
      return ['info', 'success', 'error'].includes(value);
    }
  }
});
const { type } = toRefs(props);
const { displayAlert, onCloseClick } = useAlert();
</script>

<script>
const useAlert = () => {
  const displayAlert = ref(true);

  const onCloseClick = () => {
    displayAlert.value = false;
  };

  return { onCloseClick, displayAlert };
};
</script>

<style scoped lang="scss">
.alert-error,
.alert-success,
.alert-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid;
  position: relative;
}

.alert-msg {
  width: 100%;
  text-align: center;
}

.alert-error {
  border-color: $brand-corail;
  color: $brand-corail;
}

.close-alert {
  margin-left: 10px;
}

.close-alert-btn {
  background: white;
  border: 1px solid $brand-corail;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}
</style>
