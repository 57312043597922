<template>
  <div class="read-address">
    <div class="top">
      <b>{{ address?.full_name }}</b>
      <b>{{ address?.company }}</b>
    </div>
    <div class="middle">
      <div>{{ address?.address1 }}</div>
      <div>{{ address?.address2 }}</div>
      <div>{{ address?.extra }}</div>
      <div>{{ address?.country }}</div>
    </div>
    <hr />
    <div class="bottom">
      <div>{{ address?.phone }}</div>
      <div>{{ address?.email }}</div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  address: { type: Object }
});
const { address } = toRefs(props);
</script>

<style lang="scss" scoped>
.read-address {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
  }

  > hr {
    width: 15px;
    border: 1px dashed $monsoon;
    margin: 8px 0;
  }
}
</style>
