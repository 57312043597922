<template>
  <div v-if="settings" class="settings-page">
    <div class="settings">
      <SettingCard :title="$t('settings.general.title')">
        <SettingLine :title="$t('global_settings.weight_unit')">
          <select
            :value="settings.preferences.weight_unit"
            @change="(e) => onPreferenceChange(e, 'weight_unit')"
          >
            <option v-for="weight in ['g', 'kg', 'oz', 'lbs']" :value="weight" :key="weight">
              {{ $t(`global_settings.weight_unit_${weight}`) }}
            </option>
          </select>
        </SettingLine>
        <SettingLine :title="$t('global_settings.default_merchandise')">
          <MerchandiseSelector
            @onChange="(event) => onPreferenceChange(event, 'default_content_type')"
            :selected="settings.preferences.default_content_type"
          ></MerchandiseSelector>
        </SettingLine>
        <SettingLine :title="$t('global_settings.default_delivery_signature')">
          <SignatureSelector
            @onChange="(event) => onPreferenceChange(event, 'default_signature')"
            :selected="settings.preferences.default_signature"
          ></SignatureSelector>
        </SettingLine>
        <SettingLine :title="$t('global_settings.language')">
          <select :value="settings.preferences.language" @change="onLangChange">
            <option v-for="locale in availableLocales" :value="locale" :key="locale">
              {{ locale }}
            </option>
          </select>
        </SettingLine>
        <SettingLine :title="$t('profile.export.encoding.label')">
          <select
            class="weight-select"
            :value="settings.preferences.export_file_encoding"
            @change="(e) => onPreferenceChange(e, 'export_file_encoding')"
          >
            <option v-for="encoding in ['utf8', 'shift_jis']" :value="encoding" :key="encoding">
              {{ $t(`profile.export.encoding.options.${encoding}`) }}
            </option>
          </select>
        </SettingLine>
        <SettingLine :title="$t('global_settings.bedal_scanner')">
          <input type="checkbox" v-model="settings.preferences.bedal_scanner" />
        </SettingLine>
      </SettingCard>
      <SettingCard :title="$t('settings.packages.title')">
        <SettingParcels :settings="settings" />
      </SettingCard>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import SettingCard from '../components/Settings/SettingCard.vue';
import SettingLine from '../components/Settings/SettingLine.vue';
import SettingParcels from '../components/Settings/SettingParcels.vue';
import MerchandiseSelector from '../components/Shared/MerchandiseSelector.vue';
import SignatureSelector from '../components/Shared/SignatureSelector.vue';
import { kyWithAuth } from '../libs/ky';

const { availableLocales, locale } = useI18n({ useScope: 'global' });
const { settings, fetchSettings, updateSettings, onLangChange } = useSettings({ locale });
const { onPreferenceChange } = usePreferences({ settings });

onMounted(async () => {
  await fetchSettings();
  window.onbeforeunload = () => {
    updateSettings();
  };
});

onBeforeRouteLeave((to, from, next) => {
  updateSettings();
  next();
});
</script>

<script>
const useSettings = ({ locale }) => {
  const settings = ref(null);

  const fetchSettings = async () => {
    const { settings: fetchedSettings } = await kyWithAuth.get('settings').json();
    settings.value = fetchedSettings;
  };

  const updateSettings = async () => {
    await kyWithAuth.patch(`settings/${settings.value._id}`, { json: settings.value });
    locale.value = settings.value.preferences.language;
  };

  const onLangChange = (e) => {
    const chosenLang = e.target.value;
    settings.value.preferences.language = chosenLang;
    locale.value = chosenLang;
    localStorage.setItem('lang', chosenLang);
  };

  return { settings, fetchSettings, updateSettings, onLangChange };
};

const usePreferences = ({ settings }) => {
  const onPreferenceChange = (e, preference) => {
    const newValue = e.target.value;
    settings.value.preferences[preference] = newValue;
  };

  return { onPreferenceChange };
};
</script>

<style scoped lang="scss">
.settings-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
