<template>
  <div class="pegasus-form">
    <IntegrationCard
      :title="$t('carriers.modal.credentials.title', { carrier: 'pegasus' })"
      :subtitle="$t('carriers.create_info', { carrier: 'pegasus' })"
    >
      <IntegrationInput title="forms.label.user_id" type="text" v-model="formFields.user_id" />
      <IntegrationInput
        title="forms.label.password"
        type="password"
        v-model="formFields.password"
      />
    </IntegrationCard>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue';
import { useCarriersStore } from '../../../stores/carriers.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';

const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, formFields } = useForm({
  carriersStore,
  carrier,
  isCreatedCarrier
});

// expose save/editForm to access it via ref in CarrierForm.vue
defineExpose({ saveForm, editForm });
</script>

<script>
const useForm = ({ carriersStore, carrier, isCreatedCarrier }) => {
  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { credentials } = carrier.value;
      return {
        user_id: credentials.user_id,
        password: credentials.password
      };
    }
    return {};
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const { user_id, password } = formFields;
    const carrierForm = {
      carrier: 'pegasus',
      credentials: { user_id, password },
      settings: {}
    };
    await carriersStore.create({ carrierForm });
  };

  const editForm = async () => {
    const { user_id, password } = formFields;
    const { _id } = carrier.value;
    const toUpdate = {
      carrier: 'pegasus',
      credentials: { user_id, password },
      settings: {}
    };
    await carriersStore.update({ carrierId: _id, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.pegasus-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
