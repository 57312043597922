import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Orders from '../views/Orders.vue';
import Order from '../views/Order.vue';
import Shipments from '../views/Shipments.vue';
import Shops from '../views/Shops.vue';
import Carriers from '../views/Carriers.vue';
import ShippingAddresses from '../views/ShippingAddresses.vue';
import Warehouses from '../views/Warehouses.vue';
import Settings from '../views/Settings.vue';
import Shipped from '../views/Shipped.vue';
import InvitedTeamUser from '../views/InvitedTeamUser.vue';
import Teams from '../views/Teams.vue';
import { useUserStore } from '../stores/user.js';
import { useShopsStore } from '../stores/shops.js';
import onAuthComplete from './on_auth_complete.js';

const routes = [
  {
    // for more info on the path below
    // see https://next.router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter: async (to, from, next) => {
      next({ name: 'Orders' });
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/orders/:order_id',
    name: 'Order',
    component: Order
  },
  {
    path: '/shipped/:shipment_id',
    name: 'Shipped',
    component: Shipped
  },
  {
    path: '/shipments',
    name: 'Shipments',
    component: Shipments
  },
  {
    path: '/carriers',
    name: 'Carriers',
    component: Carriers
  },
  {
    path: '/shops',
    name: 'Shops',
    component: Shops
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/addresses',
    name: 'Addresses',
    component: ShippingAddresses
  },
  {
    path: '/warehouses',
    name: 'Warehouses',
    component: Warehouses
  },
  {
    path: '/verify-email',
    beforeEnter: async (to, from, next) => {
      const userStore = useUserStore();
      window.localStorage.setItem('jwt', to.query.jwt);
      await userStore.verifyMail({ email: to.query.email });
      next({ name: 'Orders' });
    }
  },
  {
    path: '/invited-team-user',
    name: 'InvitedTeamUser',
    component: InvitedTeamUser
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/shops/auth/:shopType',
    beforeEnter: async (to, from, next) => {
      const { query: authParams, params } = to;
      const shopsStore = useShopsStore();
      await onAuthComplete[params.shopType](authParams, shopsStore);
      next({ name: 'Shops' });
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
      window.localStorage.removeItem('jwt');
      const userStore = useUserStore();
      await userStore.auth();
      next({ name: 'Login' });
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to) => {
  const jwt = window.localStorage.getItem('jwt');
  // Beware ! Infinite Loop if check only JWT
  if (!jwt && to.name !== 'Login') {
    return { name: 'Login' };
  }
  if (jwt && to.name === 'Login') {
    return { name: 'Orders' };
  }
});

export default router;
