<template>
  <div :class="`orders-${type}`">
    <header>
      <FormCheckbox />
      <div class="shop"></div>
      <div class="name">Customer</div>
      <div class="ref">Order Reference</div>
      <div class="tags">Tags</div>
      <div class="date">Date</div>
      <div class="shipping">Shipping Fee</div>
    </header>
    <router-link
      v-for="order in orders"
      class="order"
      :key="order._id"
      :to="`/orders/${order._id}`"
    >
      <FormCheckbox />
      <div class="shop">
        <IconLibrary type="logo" name="shopify" :hasMargin="false" />
      </div>
      <div class="name">{{ order.to_address.full_name }}</div>
      <div class="ref">
        <FlagLibrary :code="order.to_address.country" /> {{ order.meta.identifier }}
      </div>
      <div v-if="hasTags" class="tags"><!-- Tag Component Here --></div>
      <FormattedDate :date="order.meta.created_at" />
      <div class="shipping">450¥ Express</div>
      <div v-if="isBatched" class="batch"><!-- Batch Component Here --></div>
    </router-link>
  </div>
</template>

<script setup>
// import Tag from '../Shared/Tag.vue';
import FormattedDate from '../Shared/FormattedDate.vue';
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import FlagLibrary from '../Shared/FlagLibrary.vue';
const hasTags = false; // TODO
const isBatched = false; // TODO
</script>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true,
      default: []
    },
    type: {
      type: String,
      default: 'list'
    }
  }
};
</script>

<style lang="scss" scoped>
header,
a,
a.tags {
  display: grid;
  grid-template-columns: 40px 55px 1.2fr 0.8fr 1fr;
  grid-template-areas:
    'checkbox shop name date shipping'
    'checkbox shop ref tags tags';
  gap: 0px 0px;
}
header {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  align-items: center;
  padding: 10px 25px;
  margin: 20px 0;
  font-size: 85%;
  font-weight: $font-weight-bold;
  gap: 3px 0px;
}
a {
  grid-template-rows: 1fr 1fr;
  height: 70px;
  width: 100%;
  color: $brand-deep-purple;
  grid-template-areas:
    'checkbox shop name date shipping'
    'checkbox shop ref date shipping';
  padding: 10px 25px;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
  transition: background-color $base-speed-fast ease;
}
a.batch {
  grid-template-columns: 40px 55px 1.2fr 1.8fr;
  grid-template-areas:
    'checkbox shop name batch'
    'checkbox shop ref batch';
  .date,
  .shipping,
  .tags {
    display: none;
  }
  .batch {
    display: block;
  }
}
.orders-aside header {
  display: none;
}
.orders-aside a {
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr 1fr;
  height: 60px;
  grid-template-areas:
    'shop name'
    'shop ref';
  background-color: transparent;
  padding: 8px 0px;
  color: $brand-light-purple;
  .date,
  .shipping,
  .tags,
  .checkbox {
    display: none;
  }
  &:nth-of-type(even) {
    border-top: none;
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($brand-light-purple, 0.1);
    &.router-link-active {
      background-color: rgba($brand-light-purple, 0.4);
      cursor: default;
    }
  }
}
a:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
a:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.1);
}
a.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

// Grid Area
.checkbox {
  grid-area: checkbox;
  text-align: center;
}
.shop {
  grid-area: shop;
  display: flex;
  justify-content: center;
}
.name {
  grid-area: name;
}
.ref {
  grid-area: ref;
  display: flex;
  align-items: center;
}
.tags {
  grid-area: tags;
}
.date {
  grid-area: date;
}
.shipping {
  grid-area: shipping;
}
.batch {
  grid-area: batch;
}
</style>
