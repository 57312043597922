<template>
  <div class="parcels">
    <h3>{{ $t('order.parcels.title') }}</h3>
    <div class="parcel" v-for="(parcel, index) in parcels" :key="index">
      <button class="preset-selector" @click="showPresets = !showPresets">...</button>
      <div v-if="showPresets" class="presets-list">
        <div class="header">
          <button class="close-btn" @click="showPresets = false">x</button>
        </div>
        <div class="presets" v-for="{ title, presets } in presetsList" :key="title">
          <span class="title">{{ title }}</span>
          <div
            class="preset"
            v-for="preset in presets"
            @click="fillParcelLine({ parcel, preset })"
            :key="preset.name"
          >
            <span class="star">★</span>
            <span>{{ getPresetLine(preset) }}</span>
          </div>
        </div>
      </div>
      <div class="dimensions">
        <template v-if="parcel.type === 'carrier'">
          <input class="parcel-name" :value="parcel.name" disabled />
        </template>
        <template v-else>
          <input
            class="parcel-depth"
            :value="convertedDimension(parcel.depth)"
            @input="(event) => emitOnParcelInput({ event, parcel, dimension: 'depth' })"
          />
          <span>x</span>
          <input
            class="parcel-width"
            :value="convertedDimension(parcel.width)"
            @input="(event) => emitOnParcelInput({ event, parcel, dimension: 'width' })"
          />
          <span>x</span>
          <input
            class="parcel-height"
            :value="convertedDimension(parcel.height)"
            @input="(event) => emitOnParcelInput({ event, parcel, dimension: 'height' })"
          />
          <span>{{ dimensionUnit }}</span>
        </template>
        <input
          class="parcel-weight"
          :value="convertedWeight(parcel.weight)"
          @input="(event) => emitOnParcelInput({ event, parcel, dimension: 'weight' })"
        />
        <span>{{ weightUnit }}</span>
        <input
          class="parcel-amount"
          :value="parcel.amount"
          @input="(event) => emitOnParcelInput({ event, parcel, dimension: 'amount' })"
        />
        <span>qty</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref, onMounted, onUnmounted, computed } from 'vue';
import intersectionWith from 'lodash.intersectionwith';
import { omit } from '../../libs/utils';

const emits = defineEmits(['onParcelInput']);
const props = defineProps({
  order: { type: Object },
  settings: { type: Object },
  carriers: { type: Array }
});
const { order, settings, carriers } = toRefs(props);
const parcels = computed(() => order.value.parcels);

const { weightUnit, dimensionUnit } = getMeasurementUnits({ settings });
const { emitOnParcelInput, convertedDimension, convertedWeight, setDefaultParcel } = useParcel({
  emits,
  weightUnit,
  dimensionUnit
});
const { showPresets, presetsList, fillParcelLine, getPresetLine, hidePresetsOnOutsideClick } =
  useParcelPresets({
    settings,
    weightUnit,
    dimensionUnit,
    carriers
  });

onMounted(() => {
  setDefaultParcel({ order, settings });
  document.body.addEventListener('click', hidePresetsOnOutsideClick);
});
onUnmounted(() => {
  document.body.removeEventListener('click', hidePresetsOnOutsideClick);
});
</script>

<script>
import {
  convertDimension,
  convertToGrams,
  convertFromGrams,
  getMeasurementUnits
} from '../../libs/weight';
import fedexPackages from '../../../../common/json/fedex/packages.json';
import dhlPackages from '../../../../common/json/dhl/packages.json';
import upsPackages from '../../../../common/json/ups/packages.json';

const useParcel = ({ emits, weightUnit, dimensionUnit }) => {
  const emitOnParcelInput = ({ event, parcel, dimension }) => {
    let value = parseInt(event.target.value, 10) || 0;
    if (dimension === 'weight') {
      value = convertToGrams({ value, unit: weightUnit });
    } else if (['depth', 'width', 'height'].includes(dimension) && dimensionUnit === 'inch') {
      value = convertDimension({ value, convertTo: 'cm' });
    }
    emits('onParcelInput', { parcel, value, dimension });
  };

  const convertedDimension = (value) => {
    if (value && dimensionUnit === 'inch') {
      return convertDimension({ value, convertTo: 'inch' });
    }
    return value;
  };

  const convertedWeight = (weight) => {
    return weight == null ? '' : convertFromGrams({ value: weight, unit: weightUnit });
  };

  const setDefaultParcel = ({ order, settings }) => {
    const firstParcel = order.value.parcels?.[0];
    const isFirstParcelEmpty = ['depth', 'width', 'height', 'weight'].every(
      (dimension) => !firstParcel?.[dimension]
    );
    if (isFirstParcelEmpty) {
      const defaultCustomParcel = settings.value.custom_parcels.find((parcel) => parcel.default);
      if (defaultCustomParcel) {
        order.value.parcels[0] = { ...defaultCustomParcel, type: 'custom', amount: 1 };
      } else {
        const defaultParcel = { type: 'custom', amount: 1 };
        order.value.parcels[0] = defaultParcel;
      }
    }
  };

  return { emitOnParcelInput, convertedDimension, convertedWeight, setDefaultParcel };
};

const useParcelPresets = ({ settings, weightUnit, dimensionUnit, carriers }) => {
  const showPresets = ref(false);

  // get carrier packages for parcel presets
  const getCarrierPackages = (carrier) => {
    const carrierName = carrier.meta.type;
    const allCarrierPackages = {
      fedex: fedexPackages,
      dhl: dhlPackages,
      ups: upsPackages
    };
    const carrierPackages = allCarrierPackages[carrierName];
    // get from carrierPackages only the carrier.settings.packages
    const packagesToDisplay = intersectionWith(
      Object.keys(carrierPackages),
      carrier.settings.packages,
      (carrierPackage, userPackage) => {
        return carrierPackage === userPackage;
      }
    );
    const presets = packagesToDisplay.map((packageToDisplay) => {
      const preset = carrierPackages[packageToDisplay];
      return { ...preset, type: 'carrier', carrier: carrierName, package: packageToDisplay };
    });
    return presets;
  };

  const presetsList = computed(() => {
    const presetsList = [];

    const customParcels = settings?.value?.custom_parcels;
    if (customParcels?.length > 0) {
      const title = 'Custom Packaging Size';
      const presets = customParcels.map((preset) => {
        return { ...preset, type: 'custom' };
      });
      presetsList.push({ title, presets });
    }
    // TODO: do we need to handle here case for multiple same carriers (for example an user that have two fedex carriers) ?
    const carriersWithPackages = ['fedex', 'dhl', 'ups']; // carriers that have their own packages
    carriersWithPackages.forEach((carrierName) => {
      const carrier = carriers.value.find((carrier) => carrier.meta.type === carrierName);
      const hasPackages = carrier && carrier.settings?.packages?.length > 0;
      if (carrier && hasPackages) {
        const carrierPackages = getCarrierPackages(carrier);
        const title = `${carrierName} Packaging Size`; // TODO: i18n translation
        presetsList.push({ title, presets: carrierPackages });
      }
    });

    return presetsList;
  });

  const getPresetLine = (preset) => {
    let presetLine = preset.name;
    if (preset.type === 'custom') {
      presetLine +=
        `(${preset.depth} x ${preset.width} x ${preset.height} ${dimensionUnit} ` +
        `${preset.weight} ${weightUnit})`;
    }
    return presetLine;
  };

  const fillParcelLine = ({ parcel, preset }) => {
    if (preset.type === 'custom') {
      parcel.depth = preset.depth;
      parcel.width = preset.width;
      parcel.height = preset.height;
      parcel.weight = preset.weight;
      parcel.type = 'custom';
    } else if (preset.type === 'carrier') {
      // depth, height and width are not needed for carrier parcels
      omit(parcel, ['depth', 'height', 'width'], { mutateObj: true });
      Object.assign(parcel, preset);
    }
    showPresets.value = false;
  };

  // hide the presets list when clicking outside the component when It's displayed
  const hidePresetsOnOutsideClick = (event) => {
    if (
      showPresets.value === true &&
      !event.target.classList.contains('preset-selector') && // if the click is not on the preset selector button
      event.target.closest('.presets-list') === null // and if we are not in the presets list
    ) {
      showPresets.value = false;
    }
  };

  return { showPresets, presetsList, getPresetLine, fillParcelLine, hidePresetsOnOutsideClick };
};
</script>

<style scoped lang="scss">
.parcels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.presets-list {
  position: absolute;
  top: 35px;
  background-color: #f9f9f9;
  padding: 1px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  z-index: 3000;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
  white-space: nowrap;
  width: auto;
  min-height: 150px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .close-btn {
      border: none;
      background: none;
      cursor: pointer;
      color: $brand-corail;
      font-weight: bold;
      font-size: medium;
    }
  }
}

.presets {
  margin-top: 4px;

  .title {
    color: #908d94;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 4px;
  }

  .preset {
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    cursor: pointer;

    .star {
      margin-right: 5px;
    }
  }
}

.parcel {
  display: flex;
  flex-direction: row;
  position: relative;

  .dimensions {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .preset-selector {
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
  }

  .parcel-name {
    width: 60%;
  }
}

.parcel input {
  width: 30px;
  height: 25px;
}
</style>
