<template>
  <AppModal
    title="shipping_addresses.add_address"
    :errors="errors"
    @onClose="$emit('onCloseModal')"
    @onSave="$emit('onSaveAddress', address)"
  >
    <div class="address-modal">
      <Address :address="address" :autofulfillAddresses="autofulfillAddresses"></Address>
    </div>
  </AppModal>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import AppModal from '../../Shared/Modal/AppModal.vue';
import Address from './Address.vue';

const props = defineProps({
  address: { type: Object }, // mutated in <AddressEdit> component
  autofulfillAddresses: { type: Array },
  errors: { type: Array }
});

defineEmits(['onCloseModal', 'onSaveAddress']);
const address = ref(props.address || {});
const { autofulfillAddresses, errors } = toRefs(props);
</script>

<style lang="scss" scoped>
.address-modal {
  display: flex;
  padding: 35px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 400px;
  border-radius: 5px;
}
</style>
