<template>
  <div class="fedex-form">
    <IntegrationCard
      v-if="!isCreatedCarrier"
      :title="$t('carriers.modal.account.title', { carrier: 'fedex' })"
      :subtitle="$t('carriers.create_info', { carrier: 'fedex' })"
    >
      <IntegrationInput name="account_number" type="text" v-model="formFields.account_number" />
    </IntegrationCard>
    <IntegrationCard v-else :title="$t('carriers.modal.credentials.title', { carrier: 'fedex' })">
      <div class="client_credentials">
        <div>{{ $t('carriers.fedex.account_number') }} : {{ formFields.account_number }}</div>
      </div>
    </IntegrationCard>
    <RegistrationAddress
      v-if="!isCreatedCarrier"
      @onAddrSave="onAddrSave"
      title="carriers.fedex.registration_contact_title"
    ></RegistrationAddress>
    <IntegrationCard
      :title="$t('carriers.modal.packages.title')"
      :subtitle="$t('carriers.modal.packages.explanation')"
    >
      <IntegrationCheckboxes v-model="formFields.fedexPackages" :checkboxes="packagesCheckboxes" />
    </IntegrationCard>
    <IntegrationCard :title="$t('carriers.modal.print.title')">
      <IntegrationLabelSizes :sizes="Object.keys(fedexLabelSizes)" v-model="formFields.labelSize" />
    </IntegrationCard>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue';
import { useCarriersStore } from '../../../stores/carriers.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';
import IntegrationCheckboxes from '../../Shared/Integration/IntegrationCheckboxes.vue';
import IntegrationLabelSizes from '../../Shared/Integration/IntegrationLabelSizes.vue';
import fedexPackages from '../../../../../common/json/fedex/packages.json';
import fedexLabelSizes from '../../../../../common/json/fedex/label_sizes.json';
import RegistrationAddress from '../../Shared/Address/RegistrationAddress.vue';

const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, onAddrSave, formFields, packagesCheckboxes } = useForm({
  carriersStore,
  carrier,
  isCreatedCarrier
});

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in CarrierForm.vue
</script>

<script>
const useForm = ({ carriersStore, carrier, isCreatedCarrier }) => {
  const packagesCheckboxes = Object.keys(fedexPackages).map((key) => {
    return { value: key, name: fedexPackages[key].name };
  });

  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { credentials, settings } = carrier.value;
      return {
        account_number: credentials.account_number,
        fedexPackages: settings?.packages,
        labelSize: settings?.print_settings?.size
      };
    }
    return {
      fedexPackages: Object.keys(fedexPackages),
      signatureType: 'INDIRECT',
      labelSize: 'PDF_A4',
      registrationAddr: {}
    };
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const { account_number, fedexPackages, labelSize, registrationAddr } = formFields;
    const carrierForm = {
      carrier: 'fedex',
      credentials: { account_number },
      registration_address: registrationAddr,
      settings: {
        print_settings: { size: labelSize },
        packages: fedexPackages
      }
    };
    await carriersStore.create({ carrierForm });
  };

  const editForm = async () => {
    const { fedexPackages, labelSize } = formFields;
    const { _id } = carrier.value;
    const toUpdate = {
      carrier: 'fedex',
      settings: {
        print_settings: { size: labelSize },
        packages: fedexPackages
      }
    };
    await carriersStore.update({ carrierId: _id, body: toUpdate });
  };

  const onAddrSave = (address) => {
    formFields.registrationAddr = address;
  };

  return { saveForm, editForm, formFields, onAddrSave, packagesCheckboxes };
};
</script>

<style scoped lang="scss">
.fedex-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  color: $brand-mint;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $brand-mint;
  margin: 5px;
  cursor: pointer;
}

.edit-registration-addr {
  color: $brand-mint;
  border: 1px solid $brand-mint;
}
</style>
