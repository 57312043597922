<template>
  <div class="amazon-form">
    <IntegrationCard :title="$t('shops.amazon.edit.shop_country')" class="credentials-settings">
      <div class="credential-setting">
        <select id="shop_country" name="shop_country" v-model="formFields.shop_country">
          <option
            v-for="(marketplace, marketplaceCountry) in marketplaces"
            :value="marketplaceCountry"
            :key="marketplaceCountry"
          >
            {{ countriesByCode[marketplaceCountry.toUpperCase()] }}
          </option>
        </select>
      </div>
    </IntegrationCard>
    <IntegrationCard :title="$t('profile.invoice_ioss')">
      <IntegrationInput
        :title="$t('shops.amazon.ioss_number')"
        name="ioss_number"
        type="text"
        v-model="formFields.ioss_number"
      />
    </IntegrationCard>
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.autofulfill"
          id="autofulfill"
          name="autofulfill"
        />
        <label for="autofulfill">{{ $t('forms.label.autofulfill', { shop: 'amazon' }) }} </label>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import { useShopsStore } from '../../../stores/shops.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';
import marketplaces from '../../../../../common/json/amazon/marketplaces.json';
import countriesByCode from '../../../../../common/json/countries_by_code.json';
import { queryStringify, getRandomStr } from '../../../../../common/libs/utils.js';

const shopsStore = useShopsStore();
const props = defineProps({ shop: { type: Object } });
const { shop } = toRefs(props);
const isCreatedShop = Boolean(shop?.value?._id);

const { saveForm, editForm, formFields } = useForm({ shop, shopsStore, isCreatedShop });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, shopsStore, isCreatedShop }) => {
  const saveForm = () => {
    const selectedMarketplace = marketplaces[formFields.shop_country];
    const queryParams = {
      application_id: process.env.VUE_APP_AMAZON_APP_ID,
      state: getRandomStr()
    };
    if (process.env.NODE_ENV === 'development') {
      queryParams.version = 'beta';
    }
    const paramsStringify = queryStringify(queryParams);
    // redirect_uri is not queryStringify because amazon does not accept the url if encoded
    const redirect_uri = `${document.location.origin}/shops/auth/amazon`;
    const authUrl = `${selectedMarketplace?.seller_url}/apps/authorize/consent?${paramsStringify}&redirect_uri=${redirect_uri}`;
    localStorage.setItem('amazon', JSON.stringify({ state: queryParams.state, formFields }));
    window.location.href = authUrl;
  };

  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings } = shop.value;
      return {
        autofulfill: settings.autofulfill,
        ioss_number: settings.ioss_number,
        shop_country: settings.shop_country
      };
    }
    return {
      autofulfill: false,
      shop_country: 'jp'
    };
  };
  const formFields = reactive(defaultFormFields());

  const editForm = async () => {
    const { autofulfill, shop_country, ioss_number } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill, shop_country, ioss_number } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.amazon-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='text'] {
    width: 70%;
  }
}

.sync-setting {
  display: flex;
  align-items: center;
}

.credential-setting {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  #shop_country {
    width: 72%;
  }
}
</style>
