<template>
  <div class="shops-page">
    <div class="shops">
      <div v-for="shop in newShops" :key="shop">
        <div class="shop-logo" @click="newShopClicked = shop">
          <IconLibrary :name="shop" type="logo" />
        </div>
      </div>
      <ShopModal
        v-if="newShopClicked"
        :shopName="newShopClicked"
        @onClose="closeNewShopForm"
        title="shop.modal.add.title"
      ></ShopModal>
    </div>
    <div class="created-shops">
      <div class="created-shop" v-for="shop in shopsStore.shops" :key="shop._id">
        <span @click="createdShopClicked = shop">
          {{ shop.meta.type }} | {{ shop.meta.identifier }}
        </span>
        <span class="delete-shop" @click="deleteShop(shop)">x</span>
      </div>
      <ShopModal
        v-if="createdShopClicked"
        title="shop.modal.edit.title"
        @onClose="closeCreatedShopForm"
        :shop="createdShopClicked"
        :shopName="createdShopClicked?.meta?.type"
      ></ShopModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useShopsStore } from '../stores/shops.js';
import ShopModal from '../components/Shops/ShopModal';
import IconLibrary from '../components/Shared/IconLibrary.vue';

const shopsStore = useShopsStore();
const { newShops, deleteShop } = useShop({ shopsStore });
const { newShopClicked, closeNewShopForm, createdShopClicked, closeCreatedShopForm } =
  useShopForm();

onMounted(async () => {
  await shopsStore.fetch();
});
</script>

<script>
const useShop = ({ shopsStore }) => {
  const newShops = ['shopify', 'ebay', 'etsy', 'magento', 'amazon', 'woocommerce'];

  const deleteShop = async (shop) => {
    await shopsStore.delete({ shopType: shop.meta.type, shopId: shop._id });
  };

  return { newShops, deleteShop };
};

const useShopForm = () => {
  const newShopClicked = ref(null);
  const createdShopClicked = ref(null);

  const closeNewShopForm = () => {
    newShopClicked.value = null;
  };

  const closeCreatedShopForm = () => {
    createdShopClicked.value = null;
  };

  return { newShopClicked, createdShopClicked, closeNewShopForm, closeCreatedShopForm };
};
</script>

<style scoped lang="scss">
.shops-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shops {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.created-shops {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.created-shop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  border: dotted 1px black;
  padding: 10px;
}

.shop-logo {
  cursor: pointer;
  > img {
    width: 50px;
  }
  display: flex;
}

.delete-shop {
  cursor: pointer;
  border: 1px solid red;
  color: $brand-corail;
  padding: 2px;
  margin-left: 5px;
}
</style>
