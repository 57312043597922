<template>
  <div class="date formatted-date">
    {{ formattedDate }}
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import { format as dateFormat } from 'date-fns';

const props = defineProps({ date: { type: String }, size: { type: String } });
const { date, size } = toRefs(props);

const { formattedDate } = useFormattedDate({ date, size });
</script>

<script>
const useFormattedDate = ({ date, size }) => {
  const getFormattedDate = () => {
    const dateObj = new Date(date.value);
    if (size.value === 'short') return dateFormat(dateObj, 'P');
    if (size.value === 'long') return dateFormat(dateObj, 'PPp');

    // default value if no size is provided
    return dateFormat(dateObj, 'PP');
  };
  const formattedDate = getFormattedDate();

  return { formattedDate };
};
</script>
