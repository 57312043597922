<template>
  <div class="yamato-form">
    <IntegrationCard
      :title="$t('carriers.modal.methods.title', { carrier: 'Yamato B2' })"
      :subtitle="$t('carriers.modal.methods.explanation', { carrier: 'Yamato B2' })"
    >
      <IntegrationCheckboxes v-model="formFields.methodSettings" :checkboxes="servicesCheckboxes" />
    </IntegrationCard>
    <IntegrationCard
      :title="$t('carriers.modal.credentials.title')"
      :subtitle="
        $t('carriers.create_info', { carrier: 'Yamato B2', url :'https://bmypage.kuronekoyamato.co.jp/bmypage' }) // prettier-ignore
      "
    >
      <IntegrationInput
        title="forms.label.key"
        v-model="formFields.access_key"
        :placeholder="$t('forms.placeholder.key')"
      />
      <IntegrationInput
        title="forms.label.freight_number"
        v-model="formFields.freight_number"
        :placeholder="$t('forms.placeholder.freight_number')"
      />
    </IntegrationCard>
    <IntegrationCard :title="$t('carriers.modal.print.title')">
      <IntegrationLabelSizes :sizes="labelSizes" v-model="formFields.labelSize" />
      <IntegrationCard v-if="needLabelFallback">
        <p>{{ $t('yamato.print_settings.fallback.warning') }}</p>
        <IntegrationLabelSizes
          :sizes="labelSizes.filter((size) => size.value !== 'PDF_4.5X7.8')"
          v-model="formFields.labelSizeFallback"
        />
      </IntegrationCard>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { reactive, computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCarriersStore } from '../../../stores/carriers.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationCheckboxes from '../../Shared/Integration/IntegrationCheckboxes.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';
import IntegrationLabelSizes from '../../Shared/Integration/IntegrationLabelSizes.vue';
import yamatoSettings from '../../../../../common/json/yamato/settings.json';

const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);

const carriersStore = useCarriersStore();
const { t } = useI18n({ useScope: 'global' });
const { saveForm, editForm, formFields } = useForm({ carriersStore, carrier });
const { labelSizes, needLabelFallback } = useLabelSelector({ formFields });
const { servicesCheckboxes } = useSettings({ t });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in CarrierForm.vue
</script>

<script>
const useForm = ({ carriersStore, carrier }) => {
  const isCreatedCarrier = Boolean(carrier?.value?._id);

  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { settings, credentials } = carrier.value;
      return {
        methodSettings: settings?.methods,
        access_key: credentials.access_key,
        freight_number: credentials.freight_number,
        labelSize: settings?.print_settings?.size,
        labelSizeFallback: settings?.print_settings?.size_fallback
      };
    }
    return {
      methodSettings: yamatoSettings.services.map((service) => service.key),
      labelSize: 'PDF_A4',
      labelSizeFallback: 'PDF_A4'
    };
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const carrierForm = {
      carrier: 'yamato',
      settings: {
        methods: formFields.methodSettings,
        print_settings: { size: formFields.labelSize, size_fallback: formFields.labelSizeFallback }
      },
      credentials: {
        access_key: formFields.access_key,
        freight_number: formFields.freight_number
      }
    };
    await carriersStore.create({ carrierForm });
  };

  const editForm = async () => {
    const { methodSettings, access_key, freight_number, labelSize, labelSizeFallback } = formFields;
    const { _id } = carrier.value;
    const toUpdate = {
      carrier: 'yamato',
      settings: {
        methods: methodSettings,
        print_settings: { size: labelSize, size_fallback: labelSizeFallback }
      },
      credentials: { access_key, freight_number }
    };
    await carriersStore.update({ carrierId: _id, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};

const useLabelSelector = ({ formFields }) => {
  const labelSizes = yamatoSettings.labels.map(({ key }) => key);

  const needLabelFallback = computed(() => {
    const needFallback = ['PDF_4.5X7.8', 'PDF_4X8'];
    return needFallback.includes(formFields.labelSize);
  });

  return { labelSizes, needLabelFallback };
};

const useSettings = ({ t }) => {
  const servicesCheckboxes = yamatoSettings.services.map((service) => {
    return {
      name: t(`services.yamato.${service.key}`),
      value: service.key
    };
  });

  return { servicesCheckboxes };
};
</script>

<style scoped lang="scss">
.yamato-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yamato-form input[type='checkbox'] {
  margin-right: 5px;
}
</style>
