<template>
  <IntegrationCard>
    <JapanpostDomesticForm
      :carrier="jpostCarrier?.japanpost_domestic"
      :customerNumbers="customerNumbers"
      ref="jpostDomesticRef"
    />
    <hr />
    <JapanpostIntnlForm
      :carrier="jpostCarrier?.japanpost_international"
      :customerNumbers="customerNumbers"
      ref="jpostIntnlRef"
    />
    <hr />
    <div>
      <h2>{{ $t('carriers.japanpost.custom_account.title') }}</h2>
      <em v-html="whitelistHtmlTags($t('carriers.japanpost.create_info'))"></em>
      <p>{{ $t('forms.label.account_number') }}</p>
      <div id="account_numbers">
        <input v-model="customerNumbers[0]" size="10" maxLength="10" />
        <input v-model="customerNumbers[1]" size="6" maxLength="6" />
        <input v-model="customerNumbers[2]" size="10" maxLength="10" />
        <input v-model="customerNumbers[3]" size="6" maxLength="6" />
      </div>
    </div>
  </IntegrationCard>
</template>

<script setup>
import { toRefs, ref } from 'vue';
import IntegrationCard from '../../../Shared/Integration/IntegrationCard.vue';
import { whitelistHtmlTags } from '../../../../libs/utils.js';
import JapanpostIntnlForm from './JapanpostIntnlForm.vue';
import JapanpostDomesticForm from './JapanpostDomesticForm.vue';

// japanpost have two carrier: international and domestic
const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);
const jpostCarrier = carrier?.value?.japanpost;

const { saveForm, editForm, customerNumbers, jpostIntnlRef, jpostDomesticRef } = useForm({
  jpostCarrier
});

// expose save/editForm to access it via ref in CarrierForm.vue
defineExpose({ saveForm, editForm });
</script>

<script>
const useForm = ({ jpostCarrier }) => {
  const jpostIntnlRef = ref(null); // ref use to access save/editForm
  const jpostDomesticRef = ref(null); // ref use to access save/editForm

  // customer numbers is the same for jpost domestic or international
  const carrierCustomerNumbers =
    jpostCarrier?.japanpost_domestic?.credentials?.customer_numbers ||
    jpostCarrier?.japanpost_international?.credentials?.customer_numbers;

  const customerNumbers = ref(carrierCustomerNumbers || ['', '', '', '']);

  const saveForm = async () => {
    const intnlSaveSuccess = await jpostIntnlRef?.value?.saveForm?.();
    const domesticSaveSuccess = await jpostDomesticRef?.value?.saveForm?.();

    // if neither intnl or domestic has been selected / save when saving
    if (!intnlSaveSuccess && !domesticSaveSuccess) {
      // TODO: temporary, we need a way to handle error in the frontend (until now errors are always received from backend)
      const errors = [{ code: 'error.no_japanpost_carriers_selected' }];
      throw errors;
    }
  };

  const editForm = async () => {
    if (jpostIntnlRef.value) {
      await jpostIntnlRef.value.editForm();
    }
    if (jpostDomesticRef.value) {
      await jpostDomesticRef.value.editForm();
    }
  };

  return {
    saveForm,
    editForm,
    customerNumbers,
    jpostIntnlRef,
    jpostDomesticRef
  };
};
</script>

<style scoped lang="scss">
.japanpost-form {
  display: flex;
}

#account_numbers {
  > input:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
