<template>
  <div class="shopify-form">
    <IntegrationCard v-if="isCreatedShop">
      <span class="created-shopify-url">{{ `https://${formFields.host}.myshopify.com` }}</span>
    </IntegrationCard>
    <IntegrationCard v-else :title="$t('shop.modal.url.title')">
      <div class="shopify-url">
        <span>https://</span>
        <input v-model="formFields.host" @keyup="saveFormOnEnter" />
        <span>.myshopify.com</span>
      </div>
    </IntegrationCard>
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.autofulfill"
          id="autofulfill"
          name="autofulfill"
        />
        <label for="autofulfill">{{ $t('forms.label.autofulfill', { shop: 'shopify' }) }} </label>
      </div>
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.pending_payment"
          id="pending_payment"
          name="pending_payment"
        />
        <label for="pending_payment">
          {{ $t('forms.label.pending_payment') }}
        </label>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue';
import { useShopsStore } from '../../../stores/shops.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import { getRandomStr, queryStringify } from '../../../libs/utils';

const props = defineProps({ shop: { type: Object } });

const { shop } = toRefs(props);
const shopsStore = useShopsStore();
const isCreatedShop = Boolean(shop?.value?._id);

const { formFields, saveForm, editForm, saveFormOnEnter } = useForm({
  isCreatedShop,
  shop,
  shopsStore
});

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, isCreatedShop, shopsStore }) => {
  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings, meta } = shop.value;
      return {
        autofulfill: settings.autofulfill,
        pending_payment: settings.pending_payment,
        host: meta.identifier
      };
    }
    return {
      autofulfill: false
    };
  };
  const formFields = reactive(defaultFormFields());

  const getAuthUrl = ({ host, state }) => {
    const scopes = [
      'read_orders',
      'write_orders',
      'read_merchant_managed_fulfillment_orders', // for fulfillmentCancel mutation
      'write_merchant_managed_fulfillment_orders', // for fulfillmentCancel mutation
      'read_fulfillments',
      'write_fulfillments',
      'read_shipping',
      'read_locations',
      'read_products',
      'read_customers',
      'read_inventory',
      'read_discounts'
    ];
    const queryParams = {
      scope: scopes.join(','),
      state,
      redirect_uri: `${document.location.origin}/shops/auth/shopify`,
      client_id: process.env.VUE_APP_SHOPIFY_CLIENT_ID
    };
    const url = `https://${host}.myshopify.com/admin/oauth/authorize`;
    const authUrl = `${url}?${queryStringify(queryParams)}`;
    return authUrl;
  };

  const saveForm = async () => {
    const isDev = process.env.NODE_ENV !== 'production';
    // special case for staging as we use our own shopify credentials
    if (process.env.VUE_APP_STAGING || isDev) {
      const requestBody = { auth: {}, settings: formFields };
      await shopsStore.create({ shopType: 'shopify', requestBody });
    } else {
      const { host } = formFields;
      const state = getRandomStr();
      const authUrl = getAuthUrl({ host, state });
      localStorage.setItem('shopify', JSON.stringify({ state, settings: formFields }));
      window.location.href = authUrl;
    }
  };

  const editForm = async () => {
    const { autofulfill, pending_payment } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill, pending_payment } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  const saveFormOnEnter = (e) => {
    if (e.key === 'Enter' && formFields?.host?.length > 0) {
      saveForm();
    }
  };

  return { saveForm, editForm, saveFormOnEnter, formFields };
};
</script>

<style scoped lang="scss">
.shopify-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopify-url input {
  margin-left: 5px;
  margin-right: 5px;
}

.created-shopify-url {
  font-weight: bold;
  font-size: large;
}

.sync-settings {
  & > *:not(last-child) {
    margin-bottom: 15px;
  }
}

.sync-setting {
  display: flex;
  align-items: center;
}
</style>
