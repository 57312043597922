<template>
  <div class="rates">
    <Alert type="error" v-for="error in shipErrors" :key="error">
      {{ error.message || $t(error.code) }}
    </Alert>
    <div class="rates-data">
      <h3>Rates</h3>
      <div class="rate" v-for="rate in ratesSuccess" :key="rate.carrier_service">
        <IconLibrary :name="rate.carrier_name" type="logo" />
        <div class="title">
          <span class="carrier-name">{{ rate.title }}</span>
          <span v-if="rate?.delivery?.date" class="delivery-date">
            Est. delivery: {{ rate.delivery.date }}
          </span>
        </div>
        <MoneyFormat :amount="rate.price" :currency="rate.currency"></MoneyFormat>
        <button class="ship-btn" @click="shipOrder(rate)">Ship</button>
      </div>
      <div class="rate error" v-for="rate in ratesErrors" :key="rate.carrier_service">
        <IconLibrary :name="rate.carrier_name" type="logo" />
        <span class="title"> {{ rate.title }} </span>
        <span class="error-message" v-for="error in rate.errors" :key="error.message">
          {{ error.message }}
        </span>
      </div>
    </div>
    <button class="back-to-order-btn" @click="makeRatesEmpty">←</button>
  </div>
</template>

<script setup>
import { toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useWarehousesStore } from '../../stores/warehouses.js';
import IconLibrary from '../Shared/IconLibrary.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';
import Alert from '../Shared/Alert';
import { kyWithAuth } from '../../libs/ky';
import { getWarehouseAddr } from '../../../../common/libs/address.js';

const props = defineProps({
  order: { type: Object },
  rates: { type: Object },
  makeRatesEmpty: { type: Function }
});
const { rates, order, makeRatesEmpty } = toRefs(props);

const router = useRouter();
const warehousesStore = useWarehousesStore();
const { t } = useI18n({ useScope: 'global' });
const { shipOrder, shipErrors } = useShip({ order, router, warehousesStore });
const { ratesSuccess, ratesErrors } = useRates({ rates, t });
</script>

<script>
const useRates = ({ rates, t }) => {
  const ratesSuccess = [];
  const ratesErrors = [];

  rates.value.forEach((rate) => {
    const titleI18nKey = `services.${rate.carrier_name}.${rate.carrier_service}`;
    const titleTranslated = t(titleI18nKey);
    // If the i18n key translation is not found, the key itself is returned by i18n
    const i18nKeyDontExist = titleTranslated === titleI18nKey;
    rate.title = i18nKeyDontExist ? rate.carrier_service : titleTranslated;
    if (rate.status === 'success') ratesSuccess.push(rate);
    else if (rate.status === 'error') ratesErrors.push(rate);
  });
  return { ratesSuccess, ratesErrors };
};

const useShip = ({ order, router, warehousesStore }) => {
  const shipErrors = ref([]);

  const shipOrder = async (rate) => {
    const { _id, meta, origin, to_address, products, parcels, options } = order.value;
    const { carrier_service, carrier_id } = rate;
    const senderWarehouse = warehousesStore.getById(meta.warehouse_id);
    const isDomestic = to_address.country === senderWarehouse.address.country;
    const from_address = getWarehouseAddr({ warehouse: senderWarehouse, isDomestic });
    const json = {
      to_address,
      from_address,
      products,
      parcels,
      setup: {
        identifier: meta?.identifier,
        carrier_id,
        carrier_service,
        order_id: _id,
        order_origin_id: meta.origin_id,
        shop_id: meta.shop_id,
        shop_name: meta.type,
        shipping_method: origin.shipping_service,
        total: origin.total,
        shipping_fee: origin.shipping_amount,
        currency: meta.currency,
        signature: options.international.signature
      },
      customs: {
        content_type: 'MERCHANDISE', // test
        duty_paid: options?.international?.duty_paid
      }
    };
    try {
      /* const { shipment } =  */ await kyWithAuth.post('shipments', { json }).json();
      router.push({ name: 'Shipments' });
      // TODO: will Shipped page still exist ?
      // router.push({
      // name: 'Shipped',
      // params: { shipment_id: shipment.id, shipment: JSON.stringify(shipment) }
      // });
    } catch (err) {
      const { errors } = await err.response.json();
      shipErrors.value = errors;
    }
  };

  return { shipOrder, shipErrors };
};
</script>

<style scoped lang="scss">
.rates {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rates-data {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 22px;

  > .rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 500px;

    > *:not(:last-child) {
      margin-right: 20px;
    }

    > .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .carrier-name {
        text-align: start;
        font-size: large;
        width: 200px;
      }

      > .delivery-date {
        font-size: small;
        color: $brand-grey;
      }
    }

    > .money-format {
      display: flex;
      color: $brand-light-corail;
      width: 115px;
    }

    &.error {
      color: $brand-corail;
      align-self: flex-start;
    }
  }
}

.back-to-order-btn {
  all: unset;
  cursor: pointer;
  width: 30px;
  text-align: center;
  margin: auto;
  border: 1px solid black;
  padding: 2px;
}

.ship-btn {
  cursor: pointer;
}
</style>
