<template>
  <div v-if="warehousesStore.warehouses.length > 0" class="sender-warehouses">
    <h3>{{ $t('order.sender.title') }}</h3>
    <select
      class="warehouse-selector"
      @change="$emit('onSelectWarehouse', $event.target.value)"
      :value="order.meta.warehouse_id"
    >
      <option
        v-for="warehouse in warehousesStore.warehouses"
        :value="warehouse.id"
        :key="warehouse.id"
      >
        {{ warehouseSelectDisplay(warehouse) }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { toRefs, unref } from 'vue';
import { useWarehousesStore } from '../../stores/warehouses.js';
import { getWarehouseAddr } from '../../../../common/libs/address.js';

const warehousesStore = useWarehousesStore();
const props = defineProps({ order: { type: Object, required: true } });
const { order } = toRefs(props);
defineEmits(['onSelectWarehouse']);

const { warehouseSelectDisplay } = useWarehouses({ order });
</script>

<script>
const useWarehouses = ({ order }) => {
  const warehouseSelectDisplay = (warehouse) => {
    const isDomestic = warehouse.address.country === unref(order).to_address.country;
    const warehouseAddr = getWarehouseAddr({ warehouse, isDomestic });
    return `${warehouseAddr.address1} (${warehouseAddr.country})`;
  };

  return { warehouseSelectDisplay };
};
</script>

<style scoped lang="scss">
.sender-warehouses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.warehouse-selector {
  max-width: 225px;
}
</style>
