<template>
  <div class="sagawa-form">
    <IntegrationCard v-if="isCreatedCarrier" :title="$t('forms.label.kokyaku_code')">
      <span class="account_number" v-if="isCreatedCarrier">{{ formFields.account_number }}</span>
    </IntegrationCard>
    <IntegrationCard
      v-else
      :title="$t('forms.label.kokyaku_code')"
      :subtitle="$t('carriers.sagawa.create_info')"
    >
      <IntegrationInput
        v-model="formFields.account_number"
        :placeholder="$t('carriers.sagawa.account_placeholder')"
        maxlength="11"
      />
    </IntegrationCard>
    <template v-if="isCreatedCarrier">
      <IntegrationCard :title="$t('settings.carriers.sagawa.credentials.title')">
        <div class="requesting_help" v-if="carrier.meta.state === 'requesting'">
          {{ $t('carriers.sagawa.requesting_help') }}
        </div>
        <IntegrationInput
          v-model="formFields.api_key"
          :title="$t('carriers.sagawa.credentials')"
          :placeholder="$t('carriers.sagawa.key_placeholder')"
        />
        <IntegrationInput
          v-model="formFields.api_password"
          type="password"
          :title="$t('carriers.sagawa.password')"
          :placeholder="$t('carriers.sagawa.password_placeholder')"
        />
      </IntegrationCard>
    </template>
    <template v-else>
      <IntegrationCard>
        <div class="credentials">
          <div>
            <input type="radio" id="sagawa_request" v-model="showSagawaRequest" :value="true" />
            <label for="sagawa_request">
              {{ $t('settings.carriers.sagawa.buttons.request_keys') }}
            </label>
          </div>
          <div>
            <input type="radio" id="has_credentials" v-model="showSagawaRequest" :value="false" />
            <label for="has_credentials">
              {{ $t('settings.carriers.sagawa.buttons.has_keys') }}
            </label>
          </div>
        </div>
      </IntegrationCard>
      <RegistrationAddress
        v-if="showSagawaRequest"
        @onAddrSave="onAddrSave"
        title="carriers.sagawa.account_registration"
      ></RegistrationAddress>
      <IntegrationCard v-else :title="$t('carriers.modal.credentials.title')">
        <IntegrationInput
          v-model="formFields.api_key"
          :title="$t('carriers.sagawa.credentials')"
          :placeholder="$t('carriers.sagawa.key_placeholder')"
        />
        <IntegrationInput
          v-model="formFields.api_password"
          type="password"
          :title="$t('carriers.sagawa.password')"
          :placeholder="$t('carriers.sagawa.password_placeholder')"
        />
      </IntegrationCard>
    </template>
    <IntegrationCard :title="$t('carriers.modal.print.title')">
      <IntegrationLabelSizes :sizes="labelSizes" v-model="formFields.labelSize" />
    </IntegrationCard>
    <IntegrationCard :title="$t('settings.carriers.sagawa.daibiki.label')">
      <div class="daibiki-settings">
        <span>{{ $t('settings.carriers.sagawa.daibiki.label') }}</span>
        <div>
          <select v-model="formFields.daibiki">
            <option v-for="{ label } in sagawaSettings.daibiki" :key="label" :value="label">
              {{ $t(`settings.carriers.sagawa.daibiki.${label}`) }}
            </option>
          </select>
        </div>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs } from 'vue';
import { useCarriersStore } from '../../../stores/carriers.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';
import IntegrationLabelSizes from '../../Shared/Integration/IntegrationLabelSizes.vue';
import sagawaSettings from '../../../../../common/json/sagawa/settings.json';
import RegistrationAddress from '../../Shared/Address/RegistrationAddress.vue';

const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, formFields, labelSizes, showSagawaRequest, onAddrSave } = useForm({
  isCreatedCarrier,
  carriersStore,
  carrier
});

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in CarrierForm.vue
</script>

<script>
const useForm = ({ isCreatedCarrier, carriersStore, carrier }) => {
  // display the request access to sagawa card
  const showSagawaRequest = ref(true);

  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { settings, credentials } = carrier.value;
      return {
        account_number: credentials.account_number,
        api_key: credentials.api_key,
        api_password: credentials.api_password,
        labelSize: settings?.print_settings?.size,
        daibiki: settings?.daibiki
      };
    }
    return {
      labelSize: 'PDF_A5',
      daibiki: 'no_contract',
      registrationAddr: {}
    };
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const carrierForm = {
      carrier: 'sagawa',
      settings: {
        print_settings: { size: formFields.labelSize },
        daibiki: formFields.daibiki
      },
      credentials: {
        account_number: formFields.account_number
      }
    };
    if (showSagawaRequest.value) {
      carrierForm.meta = { state: 'requesting' };
      carrierForm.registration_address = formFields.registrationAddr;
    } else {
      carrierForm.credentials = {
        ...carrierForm.credentials,
        api_key: formFields.api_key,
        api_password: formFields.api_password
      };
    }
    await carriersStore.create({ carrierForm });
  };

  const editForm = async () => {
    const { api_key, api_password, daibiki } = formFields;
    const { _id, meta } = carrier.value;
    const toUpdate = {
      carrier: 'sagawa',
      settings: {
        print_settings: { size: formFields.labelSize },
        daibiki: daibiki
      },
      credentials: { api_key, api_password }
    };
    if (meta.state === 'requesting') {
      toUpdate.meta = { state: 'active' };
    }
    await carriersStore.update({ carrierId: _id, body: toUpdate });
  };

  const labelSizes = sagawaSettings.labels.map(({ key }) => key);

  const onAddrSave = (address) => {
    formFields.registrationAddr = address;
  };

  return {
    saveForm,
    editForm,
    formFields,
    labelSizes,
    showSagawaRequest,
    isCreatedCarrier,
    onAddrSave
  };
};
</script>

<style scoped lang="scss">
.sagawa-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.credentials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.requesting_help {
  color: $brand-light-corail;
  font-size: medium;
  margin-bottom: 10px;
}

.account-number {
  font-weight: bold;
  font-size: large;
}
</style>
