<template>
  <div class="card">
    <div v-if="title" class="title">
      <span>{{ title }}</span>
      <slot name="actions-btn"></slot>
    </div>
    <span v-if="subtitle" class="subtitle" v-html="whitelistHtmlTags(subtitle)"></span>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import { whitelistHtmlTags } from '../../../libs/utils';

const props = defineProps({
  title: { type: String },
  subtitle: { type: String }
});

const { title, subtitle } = toRefs(props);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  border: solid 1px black;
  border-radius: 5px;
  padding: 15px;
  margin: 15px;
  min-width: 90%;

  & > .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 15px;
  }

  & > .subtitle {
    margin-bottom: 10px;
    font-size: small;
    color: gray;
  }
}
</style>
