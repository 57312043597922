<template>
  <div class="integration-input">
    <label v-if="title" :for="title">{{ $t(title) }}</label>
    <input
      :name="title"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({ title: { type: String }, modelValue: { type: String } });

const { title, modelValue } = toRefs(props);
</script>

<style scoped lang="scss">
.integration-input {
  display: flex;
  flex-direction: column;

  > input {
    width: 180px;

    &[type='checkbox'] {
      margin-right: 5px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
