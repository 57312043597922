<template>
  <div class="teams">
    <h3>{{ $t('ui.teams') }}</h3>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {});
</script>

<script></script>

<style scoped lang="scss">
.teams {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
