<template>
  <IntegrationModal
    :title="`shop.modal.${isEditModal ? 'edit' : 'add'}.title`"
    :titleImgSrc="`/logos/${shopName}.svg`"
    @onClose="$emit('onClose')"
    @onSave="saveForm"
  >
    <component :is="shopForm" :shop="shop" ref="shopFormRef"></component>
  </IntegrationModal>
</template>

<script setup>
import { toRefs, ref, computed } from 'vue';
import IntegrationModal from '../Shared/Integration/IntegrationModal.vue';
import ShopifyForm from './forms/ShopifyForm';
import EbayForm from './forms/EbayForm';
import MagentoForm from './forms/MagentoForm';
import WoocommerceForm from './forms/WoocommerceForm';
import AmazonForm from './forms/AmazonForm';
import EtsyForm from './forms/EtsyForm';

const emits = defineEmits(['onClose']);
const props = defineProps({
  shopName: { type: String },
  title: { type: String },
  shop: { type: Object }
});
const { shopName, shop } = toRefs(props);
const isEditModal = computed(() => Boolean(shop?.value?._id));

const { shopForm, shopFormRef, saveForm } = useForm({ shopName, isEditModal, emits });
</script>

<script>
const useForm = ({ shopName, isEditModal, emits }) => {
  const shopsForm = {
    ebay: EbayForm,
    shopify: ShopifyForm,
    magento: MagentoForm,
    woocommerce: WoocommerceForm,
    amazon: AmazonForm,
    etsy: EtsyForm
  };
  const shopFormRef = ref(null);
  const shopForm = shopsForm[shopName.value];

  const saveForm = () => {
    // call the saveForm / editForm function of the corresponding shop template
    if (isEditModal.value) {
      shopFormRef.value.editForm();
    } else {
      shopFormRef.value.saveForm();
    }

    emits('onClose');
  };

  return { shopForm, shopFormRef, saveForm, isEditModal };
};
</script>

<style scoped lang="scss"></style>
