<template>
  <button :type="type" :id="title" class="form-button btn" v-bind="$attrs">
    {{ $t(title) }}
  </button>
</template>

<script setup>
defineProps({
  title: { type: String },
  type: { type: String, default: 'submit' }
});
</script>

<script>
export default {
  name: 'FormButton',
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
button {
  border: none;
  margin: 0;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:focus {
  outline: none;
  border-color: $brand-deep-purple;
}

button:active {
  transform: scale(0.99);
}
.btn {
  background-color: $brand-indigo;
  color: $white;
  padding: 6px 16px;
  border-radius: $base-radius;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 250ms ease, transform 150ms ease-in-out;
  height: 40px;
  &.btn-lg {
    height: 46px;
    padding: 10px 20px;
    font-size: 110%;
  }
  &:hover {
    background-color: darken($brand-indigo, 5%);
    transform: scale(0.96);
    &.btn-cancel {
      background-color: darken($brand-corail, 5%);
    }
  }
  &.btn-ghost {
    background-color: transparent;
    border: 2px solid rgba($brand-indigo, 0.3);
    color: $tuatara;
    transition: border 200ms ease, color 200ms ease, transform 200ms ease-in-out;
    &:hover {
      border: 2px solid rgba($brand-indigo, 1);
      color: $brand-indigo;
      transform: scale(0.96);
      background-color: transparent;
      &.btn-cancel {
        border: 2px solid rgba($brand-corail, 1);
        color: darken($brand-dark-corail, 15%);
      }
    }
  }
}
</style>
