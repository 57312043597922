<template>
  <teleport to="body" ref="body">
    <div class="modal" @click.self="$emit('onOutsideClick')">
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';

defineEmits(['onOutsideClick']);

// We want to hide the scrollbar for the modal
// We need to keep the initial scrollbar style to put it back after the modal is closed
const initialOverflowStyle = document.body.style.overflow;
onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = initialOverflowStyle;
});
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba($black, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 75%;
  max-height: 85%;
  background-color: white;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
</style>
