<template>
  <select
    class="merchandise-selector"
    :value="selected"
    @change="(event) => $emit('onChange', event)"
  >
    <option v-for="(value, key) in merchandiseTypes" :value="value" :key="key">
      {{ $t(`courier_rate.parcel_content.${key}`) }}
    </option>
  </select>
</template>

<script setup>
import { toRefs } from 'vue';
import merchandiseTypes from '../../json/merchandises_type.json';

const props = defineProps({ selected: String });
const { selected } = toRefs(props);

defineEmits(['onChange']);
</script>

<style scoped lang="scss">
.merchandise-selector {
  display: flex;
  width: 150px;
}
</style>
