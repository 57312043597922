<template>
  <div class="shipped">
    <!-- do we still want order identifier as shipment No. ? -->
    <h3>{{ `${$t('ui.shipment')} No. ${shipment?.delivery?.tracking_numbers?.main}` }}</h3>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { kyWithAuth } from '../libs/ky';

const route = useRoute();

const { shipment, fetchShipment } = useShipment({ route });

onMounted(() => {
  fetchShipment();
});
</script>

<script>
const useShipment = ({ route }) => {
  const shipment = ref(null);

  const fetchShipment = async () => {
    const { shipment_id, shipment: shipmentFromParams } = route.params;
    // shipment is get from params when the user come from the Rates page
    // as when the shipOrder func in Rates.vue is called, we have the shipment from the request made
    // If then this the page is reloaded for example, we need to make an api call to get the shipment
    if (shipmentFromParams) {
      shipment.value = JSON.parse(shipmentFromParams);
    } else {
      const { shipment: fetchedShipment } = await kyWithAuth.get(`shipments/${shipment_id}`).json();
      shipment.value = fetchedShipment;
    }
  };

  return { shipment, fetchShipment };
};
</script>

<style scoped lang="scss">
.shipped {
  display: flex;
  justify-content: center;
}
</style>
