<template>
  <AppModal
    :title="title"
    :errors="errors"
    @onClose="$emit('onCloseModal')"
    @onSave="$emit('onSaveAddress')"
  >
    <div class="wh-address-modal">
      <div class="wh-address-container">
        <div class="country">
          <h4>{{ $t('ui.country') }}</h4>
          <CountrySelector v-model="address.country"></CountrySelector>
        </div>
        <div class="contact">
          <h4>{{ $t('ui.contact') }}</h4>
          <div class="contact-container">
            <div class="left-position">
              <AddressInput
                title="forms.label.company_romaji_warehouse"
                v-model="address.international.company"
              />
              <AddressInput title="forms.label.company_kanji" v-model="address.domestic.company" />
              <AddressInput title="forms.label.phone" v-model="address.phone" />
            </div>
            <div class="right-position">
              <AddressInput
                title="forms.label.full_name_romaji"
                v-model="address.international.full_name"
              />
              <AddressInput
                title="forms.label.full_name_kanji"
                v-model="address.domestic.full_name"
              />
              <AddressInput title="forms.label.email" v-model="address.email" />
            </div>
          </div>
        </div>
        <div class="zip">
          <AddressInput title="forms.label.zip" v-model="address.zip" />
        </div>
        <div class="address-domestic-intnl">
          <div class="left-domestic">
            <h4>{{ $t('address.domestic.title') }}</h4>
            <AddressInput title="forms.label.prefecture" v-model="address.domestic.province" />
            <AddressInput title="forms.label.address1" v-model="address.domestic.address1" />
            <AddressInput title="forms.label.address2" v-model="address.domestic.address2" />
            <AddressInput title="forms.label.address3" v-model="address.domestic.address3" />
          </div>
          <div class="right-international">
            <h4>{{ $t('address.international.title') }}</h4>
            <AddressInput title="forms.label.address1" v-model="address.international.address1" />
            <AddressInput title="forms.label.address2" v-model="address.international.address2" />
            <AddressInput title="forms.label.address3" v-model="address.international.address3" />
            <div class="province-city">
              <AddressInput title="forms.label.province" v-model="address.international.province" />
              <AddressInput title="forms.label.city" v-model="address.international.city" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script setup>
import { toRefs } from 'vue';
import AppModal from '../Shared/Modal/AppModal.vue';
import CountrySelector from '../Shared/CountrySelector.vue';
import AddressInput from '../Shared/AddressInput.vue';

const props = defineProps({
  address: { type: Object },
  errors: { type: Array },
  title: { type: String }
});
defineEmits(['onCloseModal', 'onSaveAddress']);
const { address } = toRefs(props);
</script>

<style lang="scss">
.wh-address-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .wh-address-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-position {
      margin-right: 20px;
    }
  }

  .address-domestic-intnl {
    display: flex;
    flex-direction: row;

    .province-city {
      display: flex;
      flex-direction: row;

      input {
        width: 80px;
      }
    }
  }

  .left-domestic,
  .right-international {
    width: 50%;
    padding: 20px;
  }

  .footer {
    margin-top: 20px;
  }
}
</style>
