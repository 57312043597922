import { omit, toFloat, toInteger, getRandomStr, queryStringify } from '../../../common/libs/utils';
export {
  whitelistHtmlTags,
  getFileName,
  getRandomStr,
  toFloat,
  toInteger,
  omit,
  queryStringify,
  toRFC4648
};

// use for v-html to authorize only some html tags
// https://v3.vuejs.org/api/directives.html#v-html
function whitelistHtmlTags(str) {
  // match the opening html tag, for example in <span></span> it will match <span
  const openingTags = str.match(/<[a-z\s]+?[ >]{1}/g);
  if (!openingTags) return str;
  const whitelistTags = ['a', 'span', 'br', 'b', 'em', 'strong', 'p'];
  const isValidTags = openingTags.every((openingTag) => {
    const tag = openingTag.substring(1, openingTag.length - 1);
    return whitelistTags.includes(tag);
  });
  const isDev = process.env.NODE_ENV !== 'production';
  if (isDev && !isValidTags) {
    console.error('html tags not whitelisted, has to be whitelist in the whitelistHtmlTags func', {
      tagToWhitelist: openingTags
    });
  }
  return isValidTags ? str : '';
}

// get file name without extension
function getFileName(filepath) {
  return filepath?.split?.('/')?.pop?.()?.split?.('.')?.[0];
}

// Convert the base64 to conform to the RFC 4648: https://www.rfc-editor.org/rfc/rfc4648
// In node it correspond to base64url: https://nodejs.org/api/buffer.html#buffers-and-character-encodings
// the function below is base on this SO answer: https://stackoverflow.com/q/26736561/6548365
function toRFC4648(base64) {
  return base64.replace(/\//g, '_').replace(/\+/g, '-').replace(/=/g, '');
}
