<template>
  <div v-if="isMounted" class="order-page">
    <main>
      <Alert type="error" v-for="err in fetchRatesErr" :key="err.code">
        {{ err.message || err.code }}
      </Alert>
      <Rates
        v-if="rates.length > 0"
        :rates="rates"
        :order="order"
        :makeRatesEmpty="makeRatesEmpty"
      />
      <OrderHeader :order="order" @onOrderDelete="deleteOrder" />
      <Parcels
        :order="order"
        :settings="settings"
        @onParcelInput="setParcel"
        :carriers="carriers"
      />
      <OrderSettings
        :order="order"
        @onOrderOption="setOrderOption"
        :settings="settings"
      ></OrderSettings>
      <Products :order="order"></Products>
    </main>
    <aside>
      <OrderHeader :order="order" @onOrderDelete="deleteOrder" />
      <SenderWarehouses @onSelectWarehouse="setSelectedWarehouse" :order="order" />
      <ReceiverAddress :order="order" @onSaveAddress="onSaveReceiver" :addresses="addresses" />
      <div class="actions">
        <div class="instant">
          <IconLibrary name="stopwatch" size="sm" />
          <small>Instant Shipping</small>
        </div>
        <Loader v-if="ratesAreLoading"></Loader>
        <div v-else @click="fetchRates" class="rates">
          <IconLibrary name="money" size="sm" />
          <small>Compare Rates</small>
        </div>
      </div>
    </aside>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useWarehousesStore } from '../stores/warehouses.js';
import { useOrdersStore } from '../stores/orders.js';
// import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useRoute } from 'vue-router';
import { kyWithAuth } from '../libs/ky';
import { getWarehouseAddr } from '../../../common/libs/address.js';
import { queryStringify } from '../libs/utils';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import OrderHeader from '../components/Order/OrderHeader.vue';
import Parcels from '../components/Order/Parcels.vue';
import SenderWarehouses from '../components/Order/SenderWarehouses.vue';
import ReceiverAddress from '../components/Order/ReceiverAddress.vue';
import OrderSettings from '../components/Order/OrderSettings.vue';
import Products from '../components/Order/Products.vue';
import Rates from '../components/Order/Rates.vue';
import Loader from '../components/Shared/Loader.vue';
import Alert from '../components/Shared/Alert.vue';

const isMounted = ref(false);

onMounted(async () => {
  try {
    const route = useRoute();
    console.log('order_id', route.params.order_id);
    const orderRaw = await ordersStore.fetchOne(route.params.order_id);
    await Promise.all([fetchSettings(), fetchWarehouses(), fetchAddresses(), fetchCarriers()]);
    console.log({ orderRaw: orderRaw._id });

    // when reloading the order page we want to save changes made to the order in db
    window.onbeforeunload = () => {
      // updateOrder();
    };
    isMounted.value = true;
  } catch (e) {
    console.error(e);
  }
});

const warehousesStore = useWarehousesStore();
const ordersStore = useOrdersStore();
const order = ordersStore.order;
console.log({ order });
console.log('order_id', order._id);

const { setParcel, setOrderOption } = useOrderSettings({ order });

const { rates, fetchRates, fetchRatesErr, ratesAreLoading, makeRatesEmpty } = useRates({
  order,
  warehousesStore
});
const { onSaveReceiver, fetchAddresses, addresses } = useAddress({ order });
const { setSelectedWarehouse, fetchWarehouses } = useWarehouses({ warehousesStore, order });
const { settings, fetchSettings } = useSettings();
const { carriers, fetchCarriers } = useCarriers();

// onBeforeRouteLeave(async (to, from, next) => {
//   try {
//     // if some errors happened on updateOrder we still want to go the next page
//     // a common one is error.api.ressource_dont_exist, that happened when a shipment is made for example
//     // as the order is deleted when a shipment is made, it can't be updated
//     await updateOrder();
//   } finally {
//     next();
//   }
// });
</script>

<script>
import set from 'lodash.set';

// const useOrder = ({ route, router }) => {
//   // const order = ref(null);
//   const ordersStore = useOrdersStore();
//   const order = ordersStore.getById(route.params.order_id);

//   console.log({ order });

//   // const fetchOrder = async () => {
//   //   const orderId = route.params.order_id;
//   //   const { order: fetchedOrder } = await kyWithAuth.get(`orders/${orderId}`).json();
//   //   order.value = fetchedOrder;
//   // };

//   const updateOrder = async () => {
//     const orderId = route.params.order_id;
//     await kyWithAuth.patch(`orders/${orderId}`, { json: order });
//   };

//   const deleteOrder = async () => {
//     const isShipandcoOrder = order.meta.type === 'shipandco';
//     // shipandco orders are fully deleted whereas other orders are just soft deleted (mark as deleted)
//     const routePath = isShipandcoOrder ? `orders/shipandco/${order._id}` : `orders/${order._id}`;
//     await kyWithAuth.delete(routePath);
//     router.push({ name: 'Orders' });
//   };

//   return { order, deleteOrder, updateOrder };
// };

const useOrderSettings = ({ order }) => {
  const setParcel = ({ parcel, dimension, value }) => {
    parcel[dimension] = value;
  };

  const setOrderOption = ({ key, value }) => {
    set(order, key, value);
  };

  return { setParcel, setOrderOption };
};

const useRates = ({ order, warehousesStore }) => {
  const rates = ref([]);
  const fetchRatesErr = ref(null);
  const ratesAreLoading = ref(false);

  const fetchRates = async () => {
    const { to_address, meta, products, parcels } = order;
    const selectedWarehouse = warehousesStore.getById(meta.warehouse_id);
    const isDomestic = selectedWarehouse.address.country === order.to_address.country;
    const from_address = getWarehouseAddr({ warehouse: selectedWarehouse, isDomestic });
    const selectedProducts = products.filter((product) => product.selected);
    const setup = {
      currency: meta.currency,
      signature: order.options.international.signature
    };
    const json = {
      setup,
      to_address,
      from_address,
      products: selectedProducts,
      parcels
    };
    try {
      ratesAreLoading.value = true;
      fetchRatesErr.value = [];
      const response = await kyWithAuth.post('shipments/rates', { json }).json();
      ratesAreLoading.value = false;
      const { rates: fetchedRates } = response;
      rates.value = fetchedRates;
    } catch (err) {
      ratesAreLoading.value = false;
      const { errors } = await err.response.json();
      fetchRatesErr.value = errors;
    }
  };

  const makeRatesEmpty = () => {
    rates.value = [];
  };

  return { rates, fetchRates, makeRatesEmpty, ratesAreLoading, fetchRatesErr };
};

const useWarehouses = ({ warehousesStore, order }) => {
  const setSelectedWarehouse = (warehouseId) => {
    const selectedWarehouse = warehousesStore.getById(warehouseId);
    order.meta.warehouse_id = selectedWarehouse.id;
  };

  const fetchWarehouses = async () => {
    const warehouses = await warehousesStore.fetch();
    order.meta.warehouse_id ??= warehouses?.[0]?.id; // default sender warehouses value
  };

  return { setSelectedWarehouse, fetchWarehouses };
};

const useAddress = ({ order }) => {
  const addresses = ref([]);

  const onSaveReceiver = (address) => {
    order.to_address = { ...order.to_address, ...address };
  };

  const fetchAddresses = async () => {
    const { addresses: fetchedAddresses } = await kyWithAuth.get('addresses').json();
    addresses.value = fetchedAddresses;
  };

  return { addresses, onSaveReceiver, fetchAddresses };
};

const useSettings = () => {
  const settings = ref(null);

  const fetchSettings = async () => {
    const { settings: fetchedSettings } = await kyWithAuth.get('settings').json();
    settings.value = fetchedSettings;
  };

  return { settings, fetchSettings };
};

const useCarriers = () => {
  const carriers = ref([]);

  const fetchCarriers = async () => {
    const searchParams = queryStringify({ fields: ['meta.type', 'settings'] });
    const { carriers: fetchedCarriers } = await kyWithAuth.get('carriers', { searchParams }).json();
    carriers.value = fetchedCarriers;
  };

  return { carriers, fetchCarriers };
};
</script>

<style scoped lang="scss">
.order-page {
  display: grid;
  grid-template-columns: 1fr minmax(350px, 425px);
  height: 100%;
  > main {
    background-color: $brand-light-grey;
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 35px;
  }
  > aside {
    position: relative;
    background-color: $white;
    box-shadow: 0px 8px 18px rgba($brand-indigo, 0.08);
  }
}
aside .order-header {
  border-left: 4px solid $brand-corail;
  padding: 20px 25px;
}
.actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  .rates,
  .instant {
    padding: 14px 6px;
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 3px rgba($black, 0.25);
    transition: background-color $base-speed-normal ease;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .rates {
    background-color: $brand-indigo;
    &:hover {
      background-color: darken($brand-indigo, 5%);
    }
  }
  .instant {
    background-color: $brand-corail;

    &:hover {
      background-color: lighten($brand-corail, 5%);
    }
  }
}

// .get-rates-btn {
//   margin: 25px;

//   > button {
//     all: unset;
//     cursor: pointer;
//     border: 1px solid black;
//     padding: 5px;
//     width: 80px;
//     text-align: center;
//     border-radius: 5px;
//   }
// }

// .order-details {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: flex-start;

//   > .main {
//     margin: 15px;
//   }

//   .parcels {
//     margin-bottom: 30px;
//   }
// }

// .side-details {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   margin: 15px;
// }

// .fetch-rates-errors {
//   padding: 10px;
//   margin: 10px;
//   border: 1px solid red;
//   color: $brand-corail;
//   border-radius: 5px;

//   & > div {
//     margin: 5px;
//   }
// }
</style>
