<template>
  <h2>Japanpost domestic</h2>
  <div class="japanpost-domestic">
    <IntegrationInput
      title="forms.label.shonin_code"
      v-model="formFields.accountId"
      :placeholder="$t('forms.placeholder.account_number')"
    />
    <div class="methods">
      <h3>{{ $t('carriers.modal.methods.title') }}</h3>
      <div class="methods-settings">
        <div class="method-setting">
          <input
            type="checkbox"
            id="yuupack"
            name="yuupack"
            value="yuupack"
            v-model="formFields.methodSettings"
          />
          <label for="yuupack">yuupack</label>
        </div>
        <div class="method-setting">
          <input
            type="checkbox"
            id="yuupacket"
            name="yuupacket"
            value="yuupacket"
            v-model="formFields.methodSettings"
          />
          <label for="yuupacket">yuupacket</label>
        </div>
      </div>
    </div>
    <IntegrationLabelSizes :sizes="['PDF_A5', 'PDF_4X8']" v-model="formFields.labelSize" />
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import IntegrationLabelSizes from '../../../Shared/Integration/IntegrationLabelSizes.vue';
import IntegrationInput from '../../../Shared/Integration/IntegrationInput.vue';
import { useCarriersStore } from '../../../../stores/carriers.js';

const props = defineProps({ carrier: { type: Object }, customerNumbers: { type: Array } });
const { carrier, customerNumbers } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, formFields } = useForm({
  carriersStore,
  carrier,
  isCreatedCarrier,
  customerNumbers
});

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in JapanpostForm.vue
</script>

<script>
const useForm = ({ carriersStore, carrier, isCreatedCarrier, customerNumbers }) => {
  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { credentials, settings } = carrier.value;
      return {
        accountId: credentials?.account_id,
        labelSize: settings.print_settings?.size,
        methodSettings: settings?.methods
      };
    }
    return {
      labelSize: 'PDF_A5',
      methodSettings: []
    };
  };
  const formFields = reactive(defaultFormFields());

  const saveForm = async () => {
    const carrierForm = {
      carrier: 'japanpost_domestic',
      credentials: {
        account_id: formFields.accountId,
        customer_numbers: customerNumbers?.value
      },
      settings: {
        print_settings: {
          size: formFields.labelSize
        },
        methods: formFields.methodSettings
      }
    };
    if (carrierForm.credentials.customer_numbers?.join('')?.length === 0) {
      delete carrierForm.credentials.customer_numbers; // do not include it to not provocate schema validation
    }
    if (formFields.methodSettings?.length > 0) {
      await carriersStore.create({ carrierForm });
      return true; // to know if successfully save form or not
    }
  };

  const editForm = async () => {
    const carrierId = carrier?.value?._id;
    // two following cases are specific to japanpost (due to two carriers on the same form),
    // as we have to delete or add the carrier through the edit form
    const wantToDelete = isCreatedCarrier && formFields.methodSettings.length === 0;
    const wantToCreate = !isCreatedCarrier && formFields.methodSettings.length > 0;
    const wantToEdit = isCreatedCarrier && formFields.methodSettings.length > 0;
    if (wantToDelete) {
      await carriersStore.delete({ carrierId });
    } else if (wantToCreate) {
      await saveForm();
    } else if (wantToEdit) {
      const toUpdate = {
        carrier: 'japanpost_domestic',
        credentials: {
          account_id: formFields.acountId,
          customer_numbers: customerNumbers.value
        },
        settings: {
          print_settings: {
            size: formFields.labelSize
          },
          methods: formFields.methodSettings
        }
      };

      if (toUpdate.credentials.customer_numbers?.join('')?.length === 0) {
        // put to null, in order to delete the customer_numbers field (as is nullable, see japanpost_domestic/schema.js)
        // this only happen if user already previously saved a customer_numbers and now want to remove it
        toUpdate.credentials.customer_numbers = null;
      }
      await carriersStore.update({ carrierId, body: toUpdate });
    }
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.japanpost-domestic {
  display: flex;
  flex-direction: column;
}

.methods {
  .methods-settings {
    display: flex;
    flex-direction: row;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  .method-setting {
    display: flex;
  }

  margin-bottom: 20px;
}
</style>
