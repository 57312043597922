<template>
  <div class="order-settings">
    <div class="order-options">
      <h3>{{ $t('order.options.title') }}</h3>
      <div class="order-option">
        <input
          type="checkbox"
          :checked="order?.options?.international?.signature"
          @change="emitOnSignature"
          id="signature"
        />
        <label for="signature">{{ $t('forms.label.signature') }}</label>
      </div>
      <div class="order-option">
        <input
          type="checkbox"
          :checked="order?.options?.international?.duty_paid"
          @change="emitOnDeliveryDuty"
          id="delivery_duty"
        />
        <label for="delivery_duty">{{ $t('forms.label.delivery_duty') }}</label>
      </div>
    </div>
    <div class="order-options">
      <h3>{{ $t('order.shipment_type.title') }}</h3>
      <MerchandiseSelector
        @onChange="(event) => emitOnContentType(event)"
        :selected="order?.options?.international?.content_type"
      ></MerchandiseSelector>
    </div>
    <div class="order-options">
      <h3>{{ $t('carriers.modal.signature.title') }}</h3>
      <SignatureSelector
        @onChange="(event) => emitOnSignature(event)"
        :selected="order?.options?.international?.signature"
      ></SignatureSelector>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import MerchandiseSelector from '../Shared/MerchandiseSelector.vue';
import SignatureSelector from '../Shared/SignatureSelector.vue';

const props = defineProps({
  order: { type: Object },
  settings: { type: Object }
});
const { order, settings } = toRefs(props);
const emits = defineEmits(['onOrderOption']);

const { emitOnSignature, emitOnContentType, emitOnDeliveryDuty } = useOrderOption({
  order,
  settings,
  emits
});
</script>

<script>
const useOrderOption = ({ emits }) => {
  const emitOnSignature = (event) => {
    emits('onOrderOption', {
      key: 'options.international.signature',
      value: event.target.value
    });
  };

  const emitOnContentType = (event) => {
    emits('onOrderOption', {
      key: 'options.international.content_type',
      value: event.target.value
    });
  };

  const emitOnDeliveryDuty = (event) => {
    emits('onOrderOption', {
      key: 'options.international.duty_paid',
      value: event.target.checked
    });
  };

  return { emitOnSignature, emitOnContentType, emitOnDeliveryDuty };
};
</script>

<style scoped lang="scss">
.order-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid black;
  width: 450px;
  border-radius: 5px;
  padding: 10px;
  margin: 15px;
}

.order-options:not(:last-child) {
  margin-right: 15px;
}

.order-option {
  display: flex;
  flex-direction: row;
  align-items: center;

  > input {
    margin-right: 10px;
  }
}

h3 {
  margin-top: 0px;
}
</style>
