<template>
  <div class="address-input">
    <label :for="title">{{ $t(title) }}</label>
    <input
      :id="title"
      :name="title"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({ title: { type: String }, modelValue: { type: String } });
const { title, modelValue } = toRefs(props);
</script>

<script>
export default {
  name: 'AddressInput',
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
.address-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;

  > label {
    margin-bottom: 6px;
  }
}
</style>
