<template>
  <div class="woocommerce-form">
    <IntegrationCard v-if="isCreatedShop">
      <span class="created-shop-url">{{ formFields.identifier }}</span>
    </IntegrationCard>
    <IntegrationCard
      v-else
      :title="$t('shop.modal.credentials.title')"
      class="credentials-settings"
    >
      <div class="credential-setting">
        <label for="shop_url">{{ $t('shop.modal.url.title') }} </label>
        <input
          type="text"
          v-model="formFields.shop_url"
          :placeholder="$t('shops.woocommerce.add.shop_url_placeholder')"
          id="shop_url"
          name="shop_url"
        />
      </div>
    </IntegrationCard>
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.autofulfill"
          id="autofulfill"
          name="autofulfill"
        />
        <label for="autofulfill">
          {{ $t('forms.label.autofulfill', { shop: 'woocommerce' }) }}
        </label>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import { useUserStore } from '../../../stores/user.js';
import { useShopsStore } from '../../../stores/shops.js';
import { queryStringify } from '../../../libs/utils';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';

const userStore = useUserStore();
const shopsStore = useShopsStore();
const props = defineProps({ shop: { type: Object } });
const { shop } = toRefs(props);
const isCreatedShop = Boolean(shop?.value?._id);

const { saveForm, editForm, formFields } = useForm({ shop, userStore, shopsStore, isCreatedShop });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, userStore, shopsStore, isCreatedShop }) => {
  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings, meta } = shop.value;
      return {
        autofulfill: settings?.autofulfill,
        identifier: meta.identifier
      };
    }
    return {
      autofulfill: false
    };
  };
  const formFields = reactive(defaultFormFields());

  const getAuthUrl = (storeUrl, userId) => {
    const params = {
      app_name: 'shipandco',
      scope: 'read_write',
      user_id: userId,
      return_url: `${document.location.origin}/shops/auth/woocommerce`,
      // the callback url is a POST webhook that return consumer key / secret
      callback_url: `${process.env.VUE_APP_WEBHOOK_URL}/woocommerce/auth`
    };
    const queryParams = queryStringify(params);
    const authUrl = `${storeUrl}/wc-auth/v1/authorize?${queryParams}`;
    return authUrl;
  };

  const saveForm = () => {
    const userId = userStore.userId;
    const authUrl = getAuthUrl(formFields.shop_url, userId);
    // NOTE: woocommerce send back the user_id in the return_url (in getAuthUrl func)
    const identifier = new URL(formFields.shop_url).host;
    const toSet = JSON.stringify({ userId, settings: formFields, identifier });
    localStorage.setItem('woocommerce', toSet);
    window.location.href = authUrl;
  };

  const editForm = async () => {
    const { autofulfill } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.woocommerce-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='text'] {
    width: 70%;
  }
}
.sync-setting {
  display: flex;
  align-items: center;
}

.created-shop-url {
  font-weight: bold;
  font-size: large;
}

.credential-setting {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
