<template>
  <div class="loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="64px"
      height="64px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="50" cy="50" r="0" fill="none" stroke="#150e39" stroke-width="3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.1764705882352942s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="0s"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.1764705882352942s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="0" fill="none" stroke="#f66a58" stroke-width="3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.1764705882352942s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.5882352941176471s"
        ></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.1764705882352942s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.5882352941176471s"
        ></animate>
      </circle>
    </svg>
    Loading...
  </div>
</template>

<style scoped lang="scss">
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
}
</style>
