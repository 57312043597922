<template>
  <IntegrationCard id="registration_addr" :title="$t(title)">
    <template v-slot:actions-btn>
      <button class="edit-registration-addr" @click="() => (editAddr = !editAddr)">
        {{ editAddr ? $t('ui.save') : $t('ui.edit') }}
      </button>
    </template>
    <Address
      :autofulfillAddresses="addresses"
      :address="registrationAddr"
      :editMode="editAddr"
    ></Address>
  </IntegrationCard>
</template>

<script setup>
import { ref, toRefs, onMounted } from 'vue';
import { kyWithAuth } from '../../../libs/ky.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import Address from '../../Shared/Address/Address.vue';

const props = defineProps({ title: String });
const emits = defineEmits(['onAddrSave']);
const { title } = toRefs(props);

const { editAddr, addresses, registrationAddr, fetchAddresses } = useRegistrationAddr({ emits });

onMounted(async () => {
  await fetchAddresses();
});
</script>

<script>
const useRegistrationAddr = ({ emits }) => {
  const registrationAddr = ref({});
  const editAddr = ref(false);
  const addresses = ref([]);

  const fetchAddresses = async () => {
    const { addresses: fetchedAddresses } = await kyWithAuth.get('addresses').json();
    addresses.value = fetchedAddresses;
    // default address shown
    // TODO: For now just the first address, but it should be the contact address when we have it
    registrationAddr.value = fetchedAddresses[0]?.address;
    emits('onAddrSave', registrationAddr.value);
  };

  return { editAddr, addresses, registrationAddr, fetchAddresses };
};
</script>

<style scoped lang="scss">
.edit-registration-addr {
  color: $brand-mint;
  border: 1px solid $brand-mint;
}
button {
  color: $brand-mint;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $brand-mint;
  margin: 5px;
  cursor: pointer;
}
</style>
