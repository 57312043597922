<template>
  <div class="money-format">
    <span v-if="currency === 'EUR'">{{ `${formattedAmount} ` }}</span>
    <span class="currency">{{ symbol }}</span>
    <span v-if="currency !== 'EUR'">{{ ` ${formattedAmount} ` }}</span>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';

const props = defineProps({
  amount: { type: Number, required: true },
  currency: { type: String, required: true },
  showSymbol: { type: Boolean, default: true }
});
const { amount, currency, showSymbol } = toRefs(props);

const { formattedAmount, symbol } = useMoney({ amount, currency, showSymbol });
</script>

<script>
import currencyJs from 'currency.js';
import moneyJson from '../../../../common/json/money_format.json';

const useMoney = ({ amount, currency, showSymbol }) => {
  const formattedAmount = computed(() => {
    const precision = moneyJson?.[currency.value]?.precision ?? 2;
    const formatted = currencyJs(amount.value, { precision, symbol })?.value;
    return formatted;
  });

  const symbol = computed(() => {
    const moneyCurrency = moneyJson?.[currency.value];
    const symbolValue = showSymbol.value && moneyCurrency ? moneyCurrency.symbol : currency.value;
    return symbolValue;
  });

  return { formattedAmount, symbol };
};
</script>

<style scoped lang="scss">
.component-example {
  display: flex;
}
</style>
