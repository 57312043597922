export default useMeasurementUnit;

import { computed } from 'vue';

function useMeasurementUnit({ settings }) {
  const weightUnit = computed(() => settings?.value?.preferences?.weight_unit);
  const dimensionUnit = computed(() => {
    const isImperial = ['oz', 'lbs'].includes(weightUnit.value);
    return isImperial ? 'inch' : 'cm';
  });

  return { weightUnit, dimensionUnit };
}
