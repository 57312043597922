<template>
  <div class="product">
    <input
      v-if="hasCheckbox"
      type="checkbox"
      :id="product.name"
      :name="product.name"
      :checked="product.selected"
      @change="$emit('onProductSelect', product)"
    />
    <label class="name" :for="product.name">{{ product.name }}</label>
    <MoneyFormat :amount="product.price" :currency="order.meta.currency"></MoneyFormat>
    <span>x {{ product.quantity }}</span>
    <div class="action-btns">
      <slot name="action-btn"></slot>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';

defineEmits(['onProductSelect']);
const props = defineProps({
  order: { type: Object },
  product: { type: Object },
  hasCheckbox: { type: Boolean, default: false }
});

const { order, product } = toRefs(props);
</script>

<style scoped lang="scss">
.product {
  display: flex;
  align-items: center;
  padding: 5px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  & > *:not(:last-child) {
    margin-right: 15px;
  }
  & > input[type='checkbox'] {
    margin-right: 5px;
  }

  & > .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 58%;
  }

  .action-btns {
    display: flex;
    margin-left: auto;

    // v-slotted let us style slot (here we style the button element)
    // more info: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0023-scoped-styles-changes.md
    ::v-slotted(button):not(:last-child) {
      margin-right: 5px;
    }
  }
}
</style>
