<template>
  <div class="setting-line">
    <span v-if="title" class="title" v-html="whitelistHtmlTags(title)"></span>
    <slot></slot>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import { whitelistHtmlTags } from '../../libs/utils';

const props = defineProps({ title: { type: String } });
const { title } = toRefs(props);
</script>

<style scoped lang="scss">
.setting-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > .title {
    font-weight: bold;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
