<template>
  <div class="warehouses-page">
    <h2>{{ $t('warehouses.title') }}</h2>
    <div class="add-warehouse">
      <CreateButton @click="showCreateModal = true" title="ui.add_warehouse"></CreateButton>
    </div>
    <div class="warehouses">
      <div
        class="warehouse"
        v-for="{ id, default: defaultWarehouse, address } in warehousesWithAddr"
        :key="id"
      >
        <div class="header">
          <button @click="isDomesticDisplay[id] = false">{{ $t('ui.worldwide') }}</button>
          <button v-if="isSupportedDomestic(address.country)" @click="isDomesticDisplay[id] = true">
            {{ $t('ui.domestic') }}
          </button>
        </div>
        <AddressRead :address="address"> </AddressRead>
        <div class="footer">
          <button class="delete-warehouse" @click="deleteWarehouse(id)">
            {{ $t('ui.delete') }}
          </button>
          <div class="footer-right">
            <button v-if="defaultWarehouse" class="default-warehouse">★</button>
            <button v-else class="add-default-btn" @click="(e) => setDefaultWarehouse(id)">
              {{ $t('ui.default') }}
            </button>
            <button class="edit-warehouse" @click="displayEditModal(id)">
              {{ $t('ui.edit') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <WarehouseModal
      v-if="showCreateModal"
      title="settings.warehouse.add"
      :errors="createErrors"
      :address="warehouseToCreate"
      @onCloseModal="onCloseCreateModal"
      @onSaveAddress="createWarehouse"
    ></WarehouseModal>
    <WarehouseModal
      v-else-if="showEditModal"
      title="warehouses.edit.title"
      :errors="editErrors"
      :address="warehouseToEdit.address"
      @onCloseModal="onCloseEditModal"
      @onSaveAddress="editWarehouse"
    ></WarehouseModal>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { kyWithAuth } from '../libs/ky';
import { getWarehouseAddr, isSupportedDomestic } from '../../../common/libs/address.js';
import CreateButton from '../components/Shared/CreateButton.vue';
import AddressRead from '../components/Shared/Address/AddressRead.vue';
import WarehouseModal from '../components/Warehouses/WarehouseAddressModal.vue';
import deepClone from 'lodash.clonedeep';
import FlagLibrary from '../components/Shared/FlagLibrary.vue';

const {
  warehouses,
  fetchWarehouses,
  deleteWarehouse,
  warehousesWithAddr,
  setDefaultWarehouse,
  isDomesticDisplay
} = useWarehouses();
const {
  editWarehouse,
  warehouseToEdit,
  showEditModal,
  onCloseEditModal,
  displayEditModal,
  editErrors
} = useEditWarehouse({
  warehouses
});
const { createWarehouse, showCreateModal, warehouseToCreate, createErrors, onCloseCreateModal } =
  useCreateWarehouse({
    warehouses
  });

onMounted(async () => {
  await fetchWarehouses();
});
</script>

<script>
const useWarehouses = () => {
  const warehouses = ref([]);
  // key value pair of warehouse.id and isDisplay boolean: { [warehouse.id]: isDisplay }
  const isDomesticDisplay = ref({});

  // get warehouses addresses based on domestic / international, use only for display
  const warehousesWithAddr = computed(() =>
    warehouses.value.map((warehouse) => {
      const isDomestic = isDomesticDisplay.value[warehouse.id];
      return {
        ...warehouse,
        address: getWarehouseAddr({ warehouse, isDomestic })
      };
    })
  );

  const fetchWarehouses = async () => {
    const { warehouses: fetchedWarehouses } = await kyWithAuth.get('warehouses').json();
    warehouses.value = fetchedWarehouses;
  };

  const deleteWarehouse = async (warehouseId) => {
    await kyWithAuth.delete(`warehouses/${warehouseId}`);
    const warehouseIndex = warehouses.value.findIndex((warehouse) => warehouse.id === warehouseId);
    warehouses.value.splice(warehouseIndex, 1);
  };

  const setDefaultWarehouse = async (warehouseId) => {
    await kyWithAuth.patch(`warehouses/${warehouseId}/set-default`);
    const oldDefaultWarehouse = warehouses.value.find((warehouse) => warehouse.default);
    oldDefaultWarehouse.default = false;
    const newDefaultWarehouse = warehouses.value.find((warehouse) => warehouse.id === warehouseId);
    newDefaultWarehouse.default = true;
  };

  return {
    warehouses,
    deleteWarehouse,
    fetchWarehouses,
    setDefaultWarehouse,
    isDomesticDisplay,
    warehousesWithAddr
  };
};

const useEditWarehouse = ({ warehouses }) => {
  const showEditModal = ref(false);
  const warehouseToEdit = ref({});
  const editErrors = ref([]);

  const editWarehouse = async () => {
    const { id, address } = warehouseToEdit.value;
    try {
      const { warehouse: updatedWarehouse } = await kyWithAuth
        .patch(`warehouses/${id}`, { json: address })
        .json();
      const toUpdateIndex = warehouses.value.findIndex((wh) => wh.id === id);
      warehouses.value.splice(toUpdateIndex, 1, updatedWarehouse);

      editErrors.value = [];
      showEditModal.value = false;
    } catch (err) {
      const { errors } = await err.response.json();
      editErrors.value = errors;
    }
  };

  const displayEditModal = (warehouseId) => {
    const warehouse = warehouses.value.find((warehouse) => warehouse.id === warehouseId);
    warehouseToEdit.value = deepClone(warehouse);
    showEditModal.value = true;
  };

  const onCloseEditModal = () => {
    showEditModal.value = false;
    editErrors.value = [];
  };

  return {
    showEditModal,
    displayEditModal,
    onCloseEditModal,
    warehouseToEdit,
    editWarehouse,
    editErrors
  };
};

const useCreateWarehouse = ({ warehouses }) => {
  const showCreateModal = ref(false);
  const warehouseToCreate = reactive({ domestic: {}, international: {} });
  const createErrors = ref([]);

  const createWarehouse = async () => {
    try {
      const { warehouse: createdWarehouse } = await kyWithAuth
        .post('warehouses', { json: warehouseToCreate })
        .json();
      warehouses.value.push(createdWarehouse);
      showCreateModal.value = false;
      createErrors.value = [];
    } catch (err) {
      const { errors } = await err.response.json();
      createErrors.value = errors;
    }
  };

  const onCloseCreateModal = () => {
    showCreateModal.value = false;
    createErrors.value = [];
  };

  return {
    createWarehouse,
    showCreateModal,
    warehouseToCreate,
    createErrors,
    onCloseCreateModal
  };
};
</script>

<style scoped lang="scss">
.warehouses-page {
  display: flex;
  flex-direction: column;

  > h2 {
    text-align: center;
  }
}

.warehouses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
}

.add-warehouse {
  display: flex;
  justify-content: center;
  align-items: center;
}
.warehouse {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
  margin: 5px;
  padding: 15px;
  border-radius: 5px;
  width: 250px;
  height: 275px;

  > .header {
    display: flex;
    flex-direction: row;

    > button {
      color: $brand-mint;
      background-color: white;
      border-radius: 5px;
      border: 1px solid $brand-mint;
      margin: 5px;
      cursor: pointer;
    }
  }

  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .delete-warehouse,
    .edit-warehouse,
    .add-default-btn,
    .default-warehouse {
      background-color: white;
      height: 25px;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
    }

    .delete-warehouse {
      color: $brand-corail;
      border: 1px solid $brand-corail;
    }

    .edit-warehouse,
    .add-default-btn,
    .default-warehouse {
      color: $brand-mint;
      border: 1px solid $brand-mint;
    }
  }
}
</style>
