export { getWarehouseAddr, isSupportedDomestic };

import { omit } from './utils.js';

// to know if we support domestic for this country in S&CO (for now we only support JP)
function isSupportedDomestic(countryCode) {
  const supportedDomestic = [{ name: 'Japan', code: 'JP' }];
  return supportedDomestic.some((country) => country.code === countryCode);
}

// every warehouse contains both domestic and international addresses
// this function return the full address depending on domestic / international
function getWarehouseAddr({ warehouse, isDomestic }) {
  // address common to domestic and international
  const addressCommon = omit(warehouse.address, ['domestic', 'international']);
  if (isSupportedDomestic(warehouse.address.country) && isDomestic) {
    return { ...addressCommon, ...warehouse.address.domestic };
  }
  return { ...addressCommon, ...warehouse.address.international };
}
