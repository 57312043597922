export { convertFromGrams, convertToGrams, convertDimension, getMeasurementUnits };

import { unref } from 'vue';
import { toInteger, toFloat } from './utils';

// Convert a weight value from 'g' to kg/lbs/oz if needed
function convertFromGrams({ value, unit }) {
  if (unit === 'g') return value;
  if (unit === 'kg') return Math.ceil(100 * (toInteger(value) / 1000)) / 100;
  if (unit === 'lbs') return Math.ceil(100 * (toInteger(value) * 0.00220462262185)) / 100;
  if (unit === 'oz') return Math.ceil(toInteger(value) * 0.03527396195);
}

// Convert a weight input from kg/lbs/oz to g
function convertToGrams({ value, unit }) {
  const number = toFloat(value);
  if (unit === 'g') return value;
  if (unit === 'kg') return toInteger(number * 1000);
  if (unit === 'lbs') return Math.round(toInteger(number * 453.59237));
  if (unit === 'oz') return Math.round(toInteger(number * 28.34952));
}

function convertDimension({ value, convertTo }) {
  const number = typeof value === 'string' ? toFloat(value) : value;
  if (convertTo === 'inch') return Math.round(number * 0.393700787);
  if (convertTo === 'cm') return Math.round(number * 2.54);
}

function getMeasurementUnits({ settings }) {
  const weightUnit = unref(settings)?.preferences?.weight_unit;
  const isImperial = ['oz', 'lbs'].includes(weightUnit);
  const dimensionUnit = isImperial ? 'inch' : 'cm';
  return { weightUnit, dimensionUnit };
}
