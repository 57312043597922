<template>
  <div class="form-checkbox checkbox">
    <input type="checkbox" class="filled-in" :id="id" />
    <label :for="id" class="empty"></label>
  </div>
</template>

<script setup>
defineProps({
  id: { type: String }
});
</script>

<script>
export default {
  name: 'FormCheckbox',
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type='checkbox'] {
  + label {
    position: relative;
    padding-left: 30px;
    padding-bottom: 25px;
    top: 2px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 1rem;
    &.empty {
      padding-left: 20px;
      padding-bottom: 0px;
      background-color: $white;
    }
    &.select-all {
      font-size: 90%;
      color: grey;
    }
  }

  + label:before,
  &:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    transition: 0.2s;
  }

  &.disabled {
    + label {
      pointer-events: none;
      &:after {
        border-color: #bdbdbd !important;
        background-color: #bdbdbd !important;
      }
    }
  }

  &:not(.filled-in) + label:after {
    border: 0;
    transform: scale(0);
  }

  &:not(:checked):disabled + label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26);
  }

  // Focused styles
  &.tabbed:focus + label:after {
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// Filled in Style
[type='checkbox'].filled-in {
  // General
  + label:after {
    border-radius: 2px;
  }

  + label:before,
  + label:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
      top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
  }

  // Unchecked style
  &:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;

    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
  }

  &:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #5a5a5a;
    top: 0px;
    z-index: 0;
  }

  // Checked style
  &:checked {
    + label:before {
      top: 0;
      left: 1px;
      width: 8px;
      height: 13px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);

      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

    + label:after {
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $brand-corail;
      background-color: $brand-corail;
      z-index: 0;
    }
  }

  // Focused styles
  &.tabbed:focus + label:after {
    border-radius: 2px;
    border-color: #5a5a5a;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.tabbed:checked:focus + label:after {
    border-radius: 2px;
    background-color: $brand-corail;
    border-color: $brand-corail;
  }

  // Disabled style
  &:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent;
  }

  &:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: #bdbdbd;
  }

  &:disabled:checked + label:before {
    background-color: transparent;
  }

  &:disabled:checked + label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
}
</style>
