<template>
  <div class="shipping-addresses">
    <h2>{{ $t('shipping_addresses.title') }}</h2>
    <CreateButton
      title="shipping_addresses.create_shipping_address"
      @click="showCreateModal = true"
    ></CreateButton>
    <div class="addresses-list" v-if="addressesItems.length > 0">
      <h4>{{ $t('shipping_addresses.addresses') }}</h4>
      <Table :items="addressesItems" @onRowClick="showEditModalWithAddr" :isEditable="true">
        <template v-slot:row="{ rowIndex: addressIndex }">
          <td v-for="rowElem in addressesItems[addressIndex]" :key="rowElem">
            <span>{{ rowElem }}</span>
          </td>
          <td class="actions-buttons">
            <button class="delete-addr-btn" @click="(e) => deleteAddress(addressIndex, e)">
              x
            </button>
          </td>
        </template>
      </Table>
    </div>
    <AddressModal
      v-if="showCreateModal"
      :errors="createErrors"
      :autofulfillAddresses="addresses"
      @onCloseModal="onCloseCreateModal"
      @onSaveAddress="createAddress"
    ></AddressModal>
    <AddressModal
      v-else-if="showEditModal"
      :errors="editErrors"
      :address="addressToEdit"
      :autofulfillAddresses="addresses"
      @onCloseModal="onCloseEditModal"
      @onSaveAddress="saveEditedAddress"
    ></AddressModal>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import Table from '../components/Shared/Table.vue';
import AddressModal from '../components/Shared/Address/AddressModal.vue';
import CreateButton from '../components/Shared/CreateButton.vue';

const { fetchAddresses, deleteAddress, addressesItems, addresses } = useAddresses();
const { createAddress, showCreateModal, createErrors, onCloseCreateModal } = useCreateAddress({
  addresses
});
const {
  saveEditedAddress,
  showEditModal,
  showEditModalWithAddr,
  addressToEdit,
  editErrors,
  onCloseEditModal
} = useEditAddress({
  addresses
});

onMounted(async () => {
  await fetchAddresses();
});
</script>

<script>
import { kyWithAuth } from '../libs/ky';

const useAddresses = () => {
  const addresses = ref([]);

  const fetchAddresses = async () => {
    const { addresses: fetchedAddresses } = await kyWithAuth.get('addresses').json();
    addresses.value = fetchedAddresses;
  };

  const addressesItems = computed(() =>
    addresses.value.map(({ address }) => {
      return {
        'shipping_addresses.full_name': address.full_name,
        'ui.zip_code': address.zip,
        'shipping_addresses.address_location': address.province || address.city
      };
    })
  );

  const deleteAddress = async (addressIndex, event) => {
    event.stopPropagation();

    const addressToDelete = addresses.value[addressIndex];
    await kyWithAuth.delete(`addresses/${addressToDelete?.id}`);
    addresses.value.splice(addressIndex, 1);
  };

  return {
    fetchAddresses,
    deleteAddress,
    addresses,
    addressesItems
  };
};

const useCreateAddress = ({ addresses }) => {
  const showCreateModal = ref(false);
  const createErrors = ref([]);

  const createAddress = async (address) => {
    try {
      const { address: createdAddr } = await kyWithAuth.post('addresses', { json: address }).json();
      addresses.value.push(createdAddr);
      showCreateModal.value = false;
      createErrors.value = [];
    } catch (err) {
      const { errors } = await err.response.json();
      createErrors.value = errors;
    }
  };

  const onCloseCreateModal = () => {
    showCreateModal.value = false;
    createErrors.value = [];
  };

  return {
    createAddress,
    onCloseCreateModal,
    showCreateModal,
    createErrors
  };
};

const useEditAddress = ({ addresses }) => {
  const showEditModal = ref(false);
  const addressToEdit = ref({});
  const editErrors = ref([]);

  const saveEditedAddress = async (address) => {
    try {
      const { address: updatedAddress } = await kyWithAuth
        .patch(`addresses/${address.id}`, { json: address })
        .json();
      const addrToUpdateIndex = addresses.value.findIndex((addr) => addr.id === updatedAddress.id);
      addresses.value.splice(addrToUpdateIndex, 1, updatedAddress);

      showEditModal.value = false;
      editErrors.value = [];
    } catch (err) {
      const { errors } = await err.response.json();
      editErrors.value = errors;
    }
  };

  const onCloseEditModal = () => {
    showEditModal.value = false;
    editErrors.value = [];
  };

  const showEditModalWithAddr = (rowIndex) => {
    const { id, address } = addresses.value[rowIndex];
    // we need the id to know which address to edit on save address modal event
    addressToEdit.value = { id, ...address };
    showEditModal.value = true;
  };

  return {
    saveEditedAddress,
    showEditModal,
    showEditModalWithAddr,
    addressToEdit,
    editErrors,
    onCloseEditModal
  };
};
</script>

<style scoped lang="scss">
.shipping-addresses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addresses-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 500px;

  > h4 {
    text-align: center;
  }

  .delete-addr-btn {
    background: white;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid $brand-corail;
    color: $brand-corail;
  }
}

.actions-buttons {
  text-align: end;
}

.actions-buttons button:not(:last-child) {
  margin-right: 15px;
}
</style>
