export { useCarriersStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useCarriersStore = defineStore('carriers', {
  state: () => {
    return {
      carriers: []
    };
  },
  actions: {
    async create({ carrierForm }) {
      const { carrier } = await kyWithAuth.post('carriers', { json: carrierForm }).json();
      this.carriers.push(carrier);
      return carrier;
    },
    async update({ carrierId, body }) {
      const { carrier: updatedCarrier } = await kyWithAuth
        .patch(`carriers/${carrierId}`, { json: body })
        .json();
      const indexToUpdate = this.carriers.findIndex(
        (carrier) => carrier._id === updatedCarrier._id
      );
      this.carriers.splice(indexToUpdate, 1, updatedCarrier);
      return updatedCarrier;
    },
    async fetch() {
      const { carriers = [] } = await kyWithAuth.get('carriers').json();
      this.carriers.splice(0, this.carriers.length);
      this.carriers.push(...carriers);
      return this.carriers;
    },
    async delete({ carrierId }) {
      await kyWithAuth.delete(`carriers/${carrierId}`);
      const indexToDelete = this.carriers.findIndex((carrier) => carrier._id === carrierId);
      this.carriers.splice(indexToDelete, 1);
    }
  }
});
