<!-- EditableSettingParcel contain each element of the table row (what's inside <tr></tr>) -->
<template>
  <td>
    <input class="parcel-name" v-model="parcel.name" type="text" />
  </td>
  <td>
    <input class="parcel-length" v-model.number="parcel.depth" type="number" />
    x
    <input class="parcel-width" v-model.number="parcel.width" type="number" />
    x
    <input class="parcel-height" v-model.number="parcel.height" type="number" />
    {{ dimensionUnit }}
  </td>
  <td>
    <input class="parcel-weight" v-model.number="parcel.weight" type="number" />
    {{ weightUnit }}
  </td>
  <td class="save-btn-container">
    <!-- .stop avoid propagation of the event (https://v3.vuejs.org/guide/events.html#event-modifiers) -->
    <button class="save-btn" @click.stop="$emit('onSave', parcel)">{{ $t('ui.save') }}</button>
    <button class="cancel-preset-btn" @click.stop="$emit('onCancel')">⊘</button>
  </td>
</template>

<script setup>
import { toRefs, ref } from 'vue';
import useMeasurementUnit from '../../composables/useMeasurementUnit.js';

defineEmits(['onSave', 'onCancel']);
const props = defineProps({ parcel: { type: Object }, settings: { type: Object } });
const { settings } = toRefs(props);
// when we edit a parcel the props.parcel already exist, but when we add a new parcel it don't
// We want a copy of props.parcel as we don't want to modify the original parcel
// because on cancel we want to keep the original parcel, not the edited one
const parcelCopy = props.parcel && { ...props.parcel };
const parcel = parcelCopy || ref({});

const { weightUnit, dimensionUnit } = useMeasurementUnit({ settings });
</script>

<style scoped lang="scss">
.parcel-name {
  width: 50px;
}

.parcel-length,
.parcel-width,
.parcel-height {
  width: 22px;
}

.parcel-weight {
  width: 37px;
}

button {
  background: white;
  border-radius: 5px;
  height: 25px;
  cursor: pointer;
}

.save-btn-container {
  text-align: end;

  .save-btn {
    margin-right: 15px;
    border: 1px solid $brand-mint;
    color: $brand-mint;
  }

  .cancel-preset-btn {
    border: 1px solid $brand-corail;
    color: $brand-corail;
  }
}

// remove arrows in input[type="number"]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
