<template>
  <div class="integration-checkboxes">
    <div v-for="checkbox in checkboxes" :key="checkbox.value">
      <input
        type="checkbox"
        :id="checkbox.value"
        :value="checkbox.value"
        v-model="tickedCheckboxes"
      />
      <label :for="checkbox.value">{{ checkbox.name }}</label>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';

const emits = defineEmits('update:modelValue');
const props = defineProps(['checkboxes', 'modelValue', 'onUpdate:modelValue']);

const { checkboxes } = toRefs(props);

const tickedCheckboxes = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  }
});
</script>

<style scoped lang="scss">
.integration-checkboxes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
}
</style>
