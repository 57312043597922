<template>
  <AddressEdit
    v-if="editMode"
    :address="address"
    :autofulfillAddresses="autofulfillAddresses"
  ></AddressEdit>
  <AddressRead v-else :address="address"></AddressRead>
</template>

<script setup>
import { toRefs } from 'vue';
import AddressEdit from './AddressEdit.vue';
import AddressRead from './AddressRead.vue';

const props = defineProps({
  address: { type: Object },
  autofulfillAddresses: { type: Array },
  editMode: { type: Boolean, default: true }
});

const { address, autofulfillAddresses, editMode } = toRefs(props);
</script>
