export { useWarehousesStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useWarehousesStore = defineStore('warehouses', {
  state: () => {
    return {
      warehouses: []
    };
  },
  getters: {
    getDefault: (state) => state.warehouses.find((wh) => wh.default),
    getById: (state) => {
      return (warehouseId) => state.warehouses.find((wh) => wh.id === warehouseId);
    }
  },
  actions: {
    async fetch() {
      const { warehouses = [] } = await kyWithAuth.get('warehouses').json();
      this.warehouses.splice(0, this.warehouses.length);
      this.warehouses.push(...warehouses);
      return this.warehouses;
    }
  }
});
