<template>
  <div class="edit-address">
    <div class="left-pane">
      <div class="autofulfill-address">
        <h3>{{ $t('forms.autofill_address') }}</h3>
        <select @change="onAutofulfill">
          <option value="" disabled selected>
            {{ $t('shipping_addresses.dropdown_default') }}
          </option>
          <option v-for="{ id, address } in autofulfillAddresses" :value="id" :key="id">
            {{ `${address.full_name}, ${address.zip}, ${address.country}` }}
          </option>
        </select>
      </div>
      <div class="contact">
        <h3>{{ $t('ui.contact') }}</h3>
        <AddressInput title="forms.label.full_name" v-model="address.full_name" />
        <AddressInput title="forms.label.company" v-model="address.company" />
        <AddressInput title="forms.label.phone" v-model="address.phone" />
        <AddressInput title="forms.label.email" v-model="address.email" />
      </div>
    </div>
    <div class="right-pane">
      <div class="country">
        <h3>{{ $t('ui.country') }}</h3>
        <CountrySelector v-model="address.country"></CountrySelector>
      </div>
      <div class="address">
        <h3>{{ $t('ui.address') }}</h3>
        <AddressInput title="forms.label.address1" v-model="address.address1" />
        <AddressInput title="forms.label.address2" v-model="address.address2" />
        <div class="zip-city">
          <AddressInput title="forms.label.zip" v-model="address.zip" />
          <AddressInput title="forms.label.province" v-model="address.province" />
          <AddressInput title="forms.label.city" v-model="address.city" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import CountrySelector from '../CountrySelector.vue';
import AddressInput from '../AddressInput.vue';

const props = defineProps({
  address: { type: Object },
  autofulfillAddresses: { type: Array },
  editMode: { type: Boolean, default: true }
});

const { address, autofulfillAddresses } = toRefs(props);

const { onAutofulfill } = useAddress({ address, autofulfillAddresses });
</script>

<script>
const useAddress = ({ address, autofulfillAddresses }) => {
  const onAutofulfill = (e) => {
    const toAutofulfillId = e.target.value;
    const toAutofulfill = autofulfillAddresses.value.find((addr) => addr.id === toAutofulfillId);
    Object.assign(address.value, { id: toAutofulfill?.id, ...toAutofulfill?.address });
  };

  return { onAutofulfill };
};
</script>

<style lang="scss" scoped>
.edit-address {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  .address,
  .country {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .zip-city {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .address-input:not(:last-child) {
      margin-right: 10px;
    }

    input {
      width: 80px;
    }
  }
}
</style>
