<template>
  <IntegrationModal
    :title="`integrations.carriers.${carrierName}`"
    :titleImgSrc="`/logos/${carrierName}.svg`"
    :errors="carrierFormErrors"
    @onClose="$emit('onClose')"
    @onSave="saveForm"
  >
    <component :is="carrierForm" :carrier="carrier" ref="carrierFormRef"></component>
  </IntegrationModal>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import IntegrationModal from '../Shared/Integration/IntegrationModal.vue';
import JapanpostForm from './forms/japanpost/JapanpostForm.vue';
import FedexForm from './forms/FedexForm.vue';
import DhlForm from './forms/DhlForm.vue';
import UpsForm from './forms/UpsForm.vue';
import YamatoForm from './forms/YamatoForm.vue';
import SagawaForm from './forms/SagawaForm.vue';
import PegasusForm from './forms/PegasusForm.vue';

const emits = defineEmits(['onClose']);
const props = defineProps({
  carrier: { type: Object },
  carrierName: { type: String }
});

const { carrier, carrierName } = toRefs(props);
// in case of japanpost, carrier?.japanpost is an object containing both intnl and domestic carrier
const isEditModal = computed(() => Boolean(carrier?.value?._id || carrier?.value?.japanpost));
const { carrierForm, carrierFormRef, saveForm, carrierFormErrors } = useForm({
  carrierName,
  isEditModal,
  emits
});
</script>

<script>
const useForm = ({ carrierName, isEditModal, emits }) => {
  const carriersForm = {
    japanpost: JapanpostForm,
    fedex: FedexForm,
    dhl: DhlForm,
    ups: UpsForm,
    yamato: YamatoForm,
    sagawa: SagawaForm,
    pegasus: PegasusForm
  };
  const carrierFormRef = ref(null);
  const carrierForm = carriersForm[carrierName.value];
  const carrierFormErrors = ref([]);

  const saveForm = async () => {
    // call the saveForm / editForm function of the corresponding carrier template
    try {
      if (isEditModal.value) {
        await carrierFormRef.value.editForm();
      } else {
        await carrierFormRef.value.saveForm();
      }
      emits('onClose');
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        const error = err?.message ? { message: err.message, stack: err.stack } : err;
        const mode = isEditModal?.value ? 'editing' : 'saving';
        console.error(`something went wrong when ${mode} form:`, error);
      }
      const errors = err.response ? (await err.response.json()).errors : err;
      carrierFormErrors.value = errors;
    }
  };

  return { carrierForm, carrierFormRef, saveForm, carrierFormErrors };
};
</script>

<style scoped lang="scss"></style>
