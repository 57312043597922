export { omit, toFloat, toInteger, getRandomStr, cleanObj, getRandomNumber, queryStringify };

import deepClone from 'lodash.clonedeep';

/**
 * delete keys in obj, handle nested paths (based on this SO: https://stackoverflow.com/questions/40712249/deleting-nested-property-in-javascript-object)
 * (Same as lodash omit, but lodash omit will be drop in lodash 5 for performance reason)
 * @param {Object} obj
 * @param {Array} keysToOmit
 * @param {Object} options
 * @param {Boolean} options.mutateObj - if true, mutate the obj instead of returning a new cloned object
 * @returns {Object} object without keysToOmit
 */
function omit(obj = {}, keysToOmit = [], { mutateObj = false } = {}) {
  const clonedObj = mutateObj ? obj : deepClone(obj);
  for (const path of keysToOmit) {
    const nestedPaths = path.split('.');
    if (nestedPaths.length > 1) {
      let objCopy = clonedObj;
      const last = nestedPaths.pop();
      // iterate over the nested paths except the most nested one (pop line before)
      for (const nestedPath of nestedPaths) {
        objCopy = objCopy[nestedPath];
      }
      delete objCopy[last];
    } else {
      delete clonedObj[path];
    }
  }
  if (!mutateObj) return clonedObj;
}

const isEmptyString = (input) => {
  return typeof input === 'string' && input.trim() === '';
};

const isEmpty = (input) => {
  return input === undefined || input === null || isEmptyString(input);
};

const isValidToParse = (input) => !isEmpty(input) && !Number.isNaN(input);

function toFloat(num, defaultNum = 0) {
  return isValidToParse(num) ? parseFloat(num) : defaultNum;
}

function toInteger(num, defaultNum = 0) {
  return isValidToParse(num) ? parseInt(num) : defaultNum;
}

function getRandomStr(length = 12) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// remove from obj every null / undefined values and empty string
function cleanObj(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (
      obj[key] === null ||
      typeof obj[key] === 'undefined' ||
      (typeof obj[key] === 'string' && obj[key].length === 0)
    ) {
      return acc;
    }
    acc[key] = obj[key];
    return acc;
  }, {});
}

function queryStringify(obj) {
  const searchParams = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    // special case for Array:
    // for example { fields: ['_id', 'identifier'] } convert to fields=_id&fields=identifier
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value) => {
        searchParams.append(key, value);
      });
    } else {
      searchParams.append(key, obj[key]);
    }
  });

  const stringified = searchParams.toString();
  return stringified;
}
