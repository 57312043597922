<template>
  <div class="ebay-form">
    <IntegrationCard :title="$t('shop.modal.sync.title')" class="sync-settings">
      <div class="sync-setting">
        <input
          type="checkbox"
          v-model="formFields.autofulfill"
          id="autofulfill"
          name="autofulfill"
        />
        <label for="autofulfill">{{ $t('forms.label.autofulfill', { shop: 'ebay' }) }} </label>
      </div>
    </IntegrationCard>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue';
import { useShopsStore } from '../../../stores/shops.js';
import { getRandomStr, queryStringify } from '../../../libs/utils';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';

const shopsStore = useShopsStore();
const props = defineProps({ shop: { type: Object } });
const { shop } = toRefs(props);
const isCreatedShop = Boolean(shop?.value?._id);

const { saveForm, editForm, formFields } = useForm({ shop, shopsStore, isCreatedShop });

defineExpose({ saveForm, editForm }); // expose save/editForm to access it via ref in ShopForm.vue
</script>

<script>
const useForm = ({ shop, shopsStore, isCreatedShop }) => {
  const getAuthUrl = () => {
    const isDev = process.env.NODE_ENV !== 'production';
    const apiHost = `${isDev ? 'sandbox.' : ''}ebay.com`;

    const getAuthScope = () => {
      const authScopes = [
        'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
        'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
        'https://api.ebay.com/oauth/api_scope/sell.inventory'
      ];

      if (isDev) {
        const sandboxScopes = [
          'https://api.ebay.com/oauth/api_scope/buy.guest.order',
          'https://api.ebay.com/oauth/api_scope/buy.offer.auction'
        ];
        authScopes.push(...sandboxScopes);
      }

      return authScopes;
    };

    const uri = `https://auth.${apiHost}/oauth2/authorize`;

    const options = {
      client_id: process.env.VUE_APP_EBAY_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_EBAY_REDIRECT_NAME,
      response_type: 'code',
      state: getRandomStr(),
      scope: getAuthScope().join(' ')
    };
    localStorage.setItem('ebay-state', options.state);
    const url = `${uri}?${queryStringify(options)}`;
    return url;
  };

  const saveForm = () => {
    const authUrl = getAuthUrl();
    window.location.href = authUrl;
  };

  const defaultFormFields = () => {
    if (isCreatedShop) {
      const { settings } = shop.value;
      return {
        autofulfill: settings.autofulfill
      };
    }
    return {
      autofulfill: false
    };
  };
  const formFields = reactive(defaultFormFields());

  const editForm = async () => {
    const { autofulfill } = formFields;
    const { _id, meta } = shop.value;
    const toUpdate = { settings: { autofulfill } };
    await shopsStore.update({ shopId: _id, shopType: meta?.type, body: toUpdate });
  };

  return { saveForm, editForm, formFields };
};
</script>

<style scoped lang="scss">
.ebay-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sync-setting {
  display: flex;
  align-items: center;
}
</style>
