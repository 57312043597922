import ky from 'ky';

const kyInstance = ky.create({ prefixUrl: process.env.VUE_APP_API_HOST });

const kyWithAuth = kyInstance.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', `Bearer ${window.localStorage.getItem('jwt')}`);
      }
    ]
  }
});

export { kyInstance as default, kyWithAuth };
