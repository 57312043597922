<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header">{{ $t(header) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(_, rowIndex) in items"
          :class="{ ['is-editable']: isEditable }"
          @click="$emit('onRowClick', rowIndex)"
        >
          <!-- //NOTE: to learn more about slots props: https://v3.vuejs.org/guide/component-slots.html#scoped-slots -->
          <slot name="row" :rowIndex="rowIndex"></slot>
        </tr>
        <!-- // NOTE: sometimes we need a row outside of the rows / items provided (props.items) 
              It's the case for example when we want to add a new item to the table (as for example in Settings.vue) -->
        <tr v-if="slots?.['last-row']">
          <slot name="last-row"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
// See in Settings.vue the <Table> element for a complete example of this component utilisation
import { toRefs, useSlots } from 'vue';

const props = defineProps({ items: { type: Array }, isEditable: { type: Boolean } });
defineEmits(['onRowClick']);

const { items } = toRefs(props);
const slots = useSlots();

const headers = Object.keys(items.value[0]);
</script>

<style scoped lang="scss">
.table-container {
  display: grid;
  text-align: center;

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    tr:not(:last-child, :first-child) {
      border-bottom: 1px dashed black !important;
    }
  }
}

.is-editable {
  cursor: pointer;
  transition: background-color 0.5s ease;
}
.is-editable:hover {
  background-color: rgba($brand-mint, 0.09);
}
</style>
