<template>
  <div class="dhl-form">
    <IntegrationCard
      :title="$t('carriers.modal.account.title', { carrier: 'dhl' })"
      :subtitle="$t('carriers.create_info', { carrier: 'dhl' })"
    >
      <IntegrationInput title="forms.label.username" type="text" v-model="formFields.username" />
      <IntegrationInput
        title="forms.label.password"
        type="password"
        v-model="formFields.password"
      />
      <IntegrationInput
        title="forms.label.account_number"
        type="text"
        v-model="formFields.account_number"
      />
    </IntegrationCard>
    <IntegrationCard
      :title="$t('carriers.modal.packages.title')"
      :subtitle="$t('carriers.modal.packages.explanation')"
    >
      <IntegrationCheckboxes v-model="formFields.dhlPackages" :checkboxes="packagesCheckboxes" />
    </IntegrationCard>
    <IntegrationCard :title="$t('carriers.modal.print.title')">
      <IntegrationLabelSizes :sizes="Object.keys(dhlLabelSizes)" v-model="formFields.labelSize" />
    </IntegrationCard>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import { useCarriersStore } from '../../../stores/carriers.js';
import IntegrationCard from '../../Shared/Integration/IntegrationCard.vue';
import IntegrationInput from '../../Shared/Integration/IntegrationInput.vue';
import IntegrationLabelSizes from '../../Shared/Integration/IntegrationLabelSizes.vue';
import IntegrationCheckboxes from '../../Shared/Integration/IntegrationCheckboxes.vue';
import dhlLabelSizes from '../../../../../common/json/dhl/label_sizes.json';
import dhlPackages from '../../../../../common/json/dhl/packages.json';

const props = defineProps({ carrier: Object });
const { carrier } = toRefs(props);

const carriersStore = useCarriersStore();
const isCreatedCarrier = Boolean(carrier?.value?._id);
const { saveForm, editForm, formFields, packagesCheckboxes } = useForm({
  carriersStore,
  carrier,
  isCreatedCarrier
});

// expose save/editForm to access it via ref in CarrierForm.vue
defineExpose({ saveForm, editForm });
</script>

<script>
const useForm = ({ carriersStore, carrier, isCreatedCarrier }) => {
  const packagesCheckboxes = Object.keys(dhlPackages).map((key) => {
    return { value: key, name: dhlPackages[key].name };
  });

  const defaultFormFields = () => {
    if (isCreatedCarrier) {
      const { credentials, settings } = carrier.value;
      return {
        account_number: credentials.account_number,
        dhlPackages: settings?.packages,
        username: credentials.username,
        password: credentials.password,
        labelSize: settings?.print_settings?.size
      };
    }
    return {
      dhlPackages: Object.keys(dhlPackages),
      labelSize: 'PDF_A4'
    };
  };
  const defaultFields = defaultFormFields();
  // NOTE: spread operator in following is important as we want to keep the original defaultFields
  // for example when we want to revert form fields to their default (e.g on a fail carrier update)
  const formFields = ref({ ...defaultFields });

  const saveForm = async () => {
    const { account_number, username, password, labelSize, dhlPackages } = formFields.value;
    // test values for now
    const carrierForm = {
      carrier: 'dhl',
      credentials: { account_number, username, password },
      settings: {
        print_settings: { size: labelSize },
        packages: dhlPackages
      }
    };
    await carriersStore.create({ carrierForm });
  };

  const editForm = async () => {
    const { account_number, username, password, labelSize, dhlPackages } = formFields.value;
    const { _id } = carrier.value;
    const toUpdate = {
      carrier: 'dhl',
      credentials: { account_number, username, password },
      settings: {
        print_settings: { size: labelSize },
        packages: dhlPackages
      }
    };
    try {
      await carriersStore.update({ carrierId: _id, body: toUpdate });
    } catch (err) {
      // NOTE: .clone() is necessary because response.json() can be call only once
      // and when we rethrow the error we call it again in the saveForm function
      const response = await err.response.clone().json();
      const isAuthError = response.errors.some(
        (err) => err.code === 'INTEGRATION_ERROR.AUTHENTICATION_FAILED'
      );
      // reset the form fields to their initial value if It's an error related to the credentials
      if (isAuthError) {
        formFields.value = defaultFields;
      }
      throw err;
    }
  };

  return { saveForm, editForm, formFields, packagesCheckboxes };
};
</script>

<style scoped lang="scss">
.dhl-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
