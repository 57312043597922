<template>
  <div class="form-dropdown" tabindex="0" @blur="isOpen = false">
    <div class="selected" :class="{ 'is-open': isOpen }" @click="isOpen = !isOpen">
      <p>{{ selected }}</p>
      <IconLibrary name="arrow-down" size="xs" :hasMargin="false" :isBold="true" />
    </div>
    <div class="values" :class="{ 'is-hidden': !isOpen }">
      <div
        v-for="value in values"
        @click="
          selected = value;
          isOpen = false;
          $emit('input', value);
        "
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script setup>
import IconLibrary from '../IconLibrary.vue';
defineProps({
  values: {
    type: Array,
    required: true
  },
  default: {
    type: String
  }
});
</script>
<script>
export default {
  name: 'FormDropdown',
  data() {
    return {
      selected: this.default || this.values?.[0],
      isOpen: false
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
.form-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}

.form-dropdown .selected {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  border: 1px solid darken($brand-light-indigo, 10%);
  color: $brand-deep-purple;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 4px 0;
  }
  &.is-open {
    border: 1px solid $brand-deep-purple;
  }
  .icon-wrapper {
    margin-left: 10px;
  }
}

.form-dropdown .values {
  position: absolute;
  overflow: hidden;
  color: $brand-deep-purple;
  border: 1px solid darken($brand-light-indigo, 10%);
  border-radius: $base-radius;
  background-color: $brand-light-indigo;
  padding: 4px 0;
  z-index: 1;
  margin-top: -3px;
  min-width: 115%;
  box-shadow: 2px 2px 8px rgba($brand-indigo, 0.1);
}

.form-dropdown .values div {
  cursor: pointer;
  user-select: none;
  padding: 4px 12px;
  transition: background-color $base-speed-fast ease;
}

.form-dropdown .values div:hover {
  background-color: darken($brand-light-indigo, 5%);
}

.is-hidden {
  display: none;
}
</style>
