import { createI18n } from 'vue-i18n';

import ukI18n from '../../public/i18n/en-UK.i18n.json';
import frI18n from '../../public/i18n/fr-FR.i18n.json';
import jpI18n from '../../public/i18n/ja-JP.i18n.json';
import zhCnI18n from '../../public/i18n/zh-CN.i18n.json';
import zhI18n from '../../public/i18n/zh.i18n.json';

const i18nFiles = {
  'en-UK': ukI18n,
  'fr-FR': frI18n,
  'ja-JP': jpI18n,
  'zh-CN': zhCnI18n,
  zh: zhI18n
};

const availableLocales = Object.keys(i18nFiles);

const getDefaultLocale = () => {
  const availableLocale = availableLocales.find((locale) => locale.includes(navigator.language));
  return availableLocale || 'en-UK';
};

// see https://vue-i18n.intlify.dev/guide/advanced/composition.html for more details on createI18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  missingWarn: false,
  fallbackWarn: false,
  locale: getDefaultLocale(),
  availableLocales: ['en-UK', 'fr-FR', 'ja-JP', 'zh-CN', 'zh'],
  messages: i18nFiles
});

export default i18n;
