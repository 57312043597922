export { useShopsStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useShopsStore = defineStore('shops', {
  state: () => {
    return {
      shops: []
    };
  },
  actions: {
    async create({ shopType, requestBody }) {
      const { shop } = await kyWithAuth.post(`shops/${shopType}`, { json: requestBody }).json();
      this.shops.push(shop);
      return shop;
    },
    async update({ shopType, shopId, body }) {
      const { shop: updatedShop } = await kyWithAuth
        .patch(`shops/${shopType}/${shopId}`, { json: body })
        .json();
      const indexToUpdate = this.shops.findIndex((shop) => shop._id === updatedShop._id);
      this.shops.splice(indexToUpdate, 1, updatedShop);
      return updatedShop;
    },
    async fetch() {
      const { shops = [] } = await kyWithAuth.get('shops').json();
      this.shops.splice(0, this.shops.length);
      this.shops.push(...shops);
      return this.shops;
    },
    async delete({ shopType, shopId }) {
      await kyWithAuth.delete(`shops/${shopType}/${shopId}`);
      const indexToDelete = this.shops.findIndex((shop) => shop._id === shopId);
      this.shops.splice(indexToDelete, 1);
    }
  }
});
