module.exports = {
  shopify: async (authParams, shopsStore) => {
    const { state, settings } = JSON.parse(localStorage.getItem('shopify'));
    if (authParams?.state !== state) return;
    localStorage.removeItem('shopify');
    const requestBody = { auth: authParams, settings };
    await shopsStore.create({ shopType: 'shopify', requestBody });
  },
  etsy: async (authParams, shopsStore) => {
    const { auth: authLocalStorage, settings } = JSON.parse(localStorage.getItem('etsy'));
    if (authParams?.state !== authLocalStorage.state) return;
    localStorage.removeItem('etsy');
    const { codeVerifier, redirectUri } = authLocalStorage;
    const authRequest = { codeVerifier, redirectUri, code: authParams.code };
    const requestBody = { auth: authRequest, settings };
    await shopsStore.create({ shopType: 'etsy', requestBody });
  },
  ebay: async (authParams, shopsStore) => {
    if (authParams?.state !== localStorage.getItem('ebay-state')) return;
    localStorage.removeItem('ebay-state');
    const settings = { autofulfill: true, notify_customer: true, pending_payment: false }; // test values for now
    const requestBody = { auth: authParams, settings };
    await shopsStore.create({ shopType: 'ebay', requestBody });
  },
  woocommerce: async (authParams, shopsStore) => {
    const { userId, settings, identifier } = JSON.parse(localStorage.getItem('woocommerce'));
    if (authParams?.user_id !== userId) return;
    localStorage.removeItem('woocommerce');
    // NOTE: specific case for woocommerce due to their specific auth flow.
    // The shop is created on an other webhook received in the backend (see shopAuth in webhooks_actions.js)
    // But is not yet created in the frontend, so we refresh shops in order to get it
    const shops = await shopsStore.fetch();
    const shopId = shops.find((shop) => shop.meta.identifier === identifier)?._id;
    await shopsStore.update({ shopType: 'woocommerce', shopId, body: { settings } });
    return settings;
  },
  amazon: async (authParams, shopsStore) => {
    const { state, formFields: settings } = JSON.parse(localStorage.getItem('amazon'));
    if (authParams?.state !== state) return;
    localStorage.removeItem('amazon');
    const requestBody = { auth: authParams, settings };
    await shopsStore.create({ shopType: 'amazon', requestBody });
    return settings;
  }
};
