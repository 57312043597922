<template>
  <div class="shipments-page">
    <h2>{{ $t('shipments.intro') }}</h2>
    <div class="shipment" v-for="shipment in shipments" :key="shipment.id">
      <IconLibrary :name="shipment.delivery.carrier" type="logo" />
      <a :href="trackingUrl(shipment)" target="_blank">{{
        shipment?.delivery?.tracking_numbers?.main
      }}</a>
      <span>{{ shipment.identifier }}</span>
      <span>{{ shipment.to_address.country }}</span>
      <button class="label-btn" @click="modalSrc = shipment.delivery.label_url">
        {{ $t('ui.label') }}
      </button>
      <button
        v-if="shipment.delivery.carrier_invoice_url"
        class="carrier-invoice-btn"
        @click="modalSrc = shipment.delivery.carrier_invoice_url"
      >
        {{ $t('ui.carrier_invoice') }}
      </button>
      <button class="del-fulfillment-btn" @click="deleteShipment(shipment.id)">x</button>
    </div>
    <Modal v-if="modalSrc" @onOutsideClick="modalSrc = null">
      <embed id="pdf" :src="modalSrc" />
    </Modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '../components/Shared/Modal/Modal.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import getTrackingUrl from '../../../common/libs/get_tracking_url';
import { kyWithAuth } from '../libs/ky';

const { locale } = useI18n({ useScope: 'global' });
const { shipments, deleteShipment, fetchShipments } = useShipments();
const { trackingUrl } = useTracking({ locale });
const modalSrc = ref(null);

onMounted(async () => {
  await fetchShipments();
});
</script>

<script>
const useShipments = () => {
  const shipments = ref(null);

  const fetchShipments = async () => {
    const { shipments: fetchedShipments } = await kyWithAuth.get('shipments').json();
    shipments.value = fetchedShipments;
  };

  const deleteShipment = async (shipmentId) => {
    await kyWithAuth.delete(`shipments/${shipmentId}`);
    const toDeleteIndex = shipments.value.findIndex((s) => s.id === shipmentId);
    shipments.value.splice(toDeleteIndex, 1);
  };

  return { shipments, deleteShipment, fetchShipments };
};

const useTracking = ({ locale }) => {
  const trackingUrl = (shipment) => {
    const tracking = shipment?.delivery?.tracking_numbers?.main;
    const carrierName = shipment?.delivery?.carrier;
    return getTrackingUrl({ tracking, carrierName, lang: locale.value });
  };

  return { trackingUrl };
};
</script>

<style scoped lang="scss">
.shipments-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shipment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 12px;

  & > *:not(:last-child) {
    margin-right: 15px;
  }
}

.label-btn,
.carrier-invoice-btn {
  cursor: pointer;
  background: white;
  padding: 5px;
  border: 1px solid $brand-mint;
  border-radius: 5px;
  color: $brand-mint;
}

.del-fulfillment-btn {
  background: white;
  border: 1px solid $brand-corail;
  border-radius: 5px;
  color: $brand-corail;
  cursor: pointer;
}
</style>
