<template>
  <div class="setting-card">
    <span class="title">{{ title }}</span>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({ title: { type: String } });

const { title } = toRefs(props);
</script>

<style scoped lang="scss">
.setting-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px black;
  border-radius: 5px;
  margin: 15px;
  padding: 15px;
  width: 600px;

  > .title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: large;
  }

  > .card-content {
    width: 100%;
  }
}
</style>
