<template>
  <select
    class="signature-selector"
    :value="selected"
    @change="(event) => $emit('onChange', event)"
  >
    <option v-for="(value, key) in signatureTypes" :value="value" :key="key">
      {{ $t(`carriers.modal.signature.${value}`) }}
    </option>
  </select>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({ selected: String });
const { selected } = toRefs(props);
const signatureTypes = ['carrier_default', 'direct', 'indirect', 'adult'];

defineEmits(['onChange']);
</script>

<style scoped lang="scss">
.signature-selector {
  display: flex;
  width: 150px;
}
</style>
