import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';

const pinia = createPinia();
const app = createApp(App);

if (process.env.NODE_ENV === 'development') {
  app.config.devtools = true;
}

app.use(pinia);
app.use(i18n);
app.use(router);

app.mount('#root');
