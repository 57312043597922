<template>
  <div id="app" :class="[routeName, userStore.isLogged ? 'authenticated' : 'anon']">
    <Authenticated v-if="userStore.isLogged" />
    <Anonymous v-else />
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUserStore } from './stores/user';
import { queryStringify } from './libs/utils.js';
import Authenticated from './views/Authenticated.vue';
import Anonymous from './views/Anonymous.vue';

const userStore = useUserStore();
const route = useRoute();
const routeName = computed(() => route?.name?.toLocaleLowerCase());
const { locale } = useI18n({ useScope: 'global' });
const { initLang } = useInit({ userStore, route, locale });

onMounted(async () => {
  try {
    await userStore.auth();
    await initLang();
  } catch (e) {
    console.error(e);
  }
});
</script>

<script>
import { kyWithAuth } from './libs/ky';
const useInit = ({ userStore, locale }) => {
  const getUserLang = async () => {
    const storageLang = localStorage.getItem('lang');
    if (storageLang) return storageLang;
    const searchParams = queryStringify({ fields: ['preferences.language'] });
    const { settings } = await kyWithAuth.get('settings', { searchParams }).json();
    const userLang = settings?.preferences?.language;
    localStorage.setItem('lang', userLang);
    return userLang;
  };

  const initLang = async () => {
    if (userStore.isLogged) {
      locale.value = await getUserLang();
    }
  };
  return { initLang };
};
</script>

<style lang="scss">
@import './scss/layout.scss';
#app.authenticated {
  display: grid;
  grid-template-columns: 275px 1fr;
  grid-template-rows: 70px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'MainNav Header'
    'MainNav Main';
  & > header {
    grid-area: Header;
    background-color: $brand-light-indigo;
  }
  & > nav {
    grid-area: MainNav;
    background-color: $brand-dark-purple;
  }
  & > aside {
    display: none;
    background-color: $brand-deep-purple;
    overflow-y: auto;
    overflow-x: hidden;
  }
  & > main {
    grid-area: Main;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
#app.authenticated.order {
  grid-template-columns: 72px 275px 1fr;
  grid-template-areas:
    'MainNav OrderNav Header'
    'MainNav OrderNav Main';
  & > aside {
    display: block;
    grid-area: OrderNav;
  }
}
</style>
