<template>
  <div class="order-header">
    <div class="header-top">
      <div class="left">
        <div class="title-container">
          <span class="title">{{ $t('order.information.title') }}: </span>
          <span class="identifier">{{ order.meta.identifier }}</span>
        </div>
        <a v-if="shopOrderLink" :href="shopOrderLink" target="_blank">
          ★ {{ $t('shop.see_on_shopify') }}
        </a>
      </div>
      <div class="right">
        <button class="delete-order" @click="$emit('onOrderDelete')">
          {{ $t('ui.delete') }}
        </button>
      </div>
    </div>
    <hr />
    <div class="header-bottom">
      <div class="line-top">
        <div class="date">
          <b>{{ $t('ui.date') }}:</b>
          <FormattedDate :date="order.meta.created_at" />
        </div>
        <div v-if="discountPercent" class="discount_percent">
          <b>{{ $t('ui.discount') }}:</b>
          <span>{{ discountPercent }}%</span>
        </div>
        <div class="order-total" v-if="order.origin.total">
          <b>{{ $t('ui.total') }}:</b>
          <MoneyFormat :amount="order.origin.total" :currency="order.meta.currency" />
        </div>
      </div>
      <div class="line-bottom">
        <div class="shipping-method" v-if="order.origin.shipping_service">
          <b>{{ $t('ui.shipping_method') }}:</b>
          <span>{{ order.origin.shipping_service }}</span>
        </div>
        <div class="shipping-fee">
          <b>{{ $t('ui.shipping_fee') }}:</b>
          <MoneyFormat :amount="order.origin.shipping_amount" :currency="order.meta.currency" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import FormattedDate from '../Shared/FormattedDate.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';

defineEmits(['onOrderDelete']);
const props = defineProps({ order: { type: Object } });
const { order } = toRefs(props);

const { discountPercent, shopOrderLink } = useOrderHeader({ order });
</script>

<script>
const useOrderHeader = ({ order }) => {
  const getDiscountPercent = () => {
    const originDiscount = order?.value?.origin?.discount || 0;
    const productDiscount = Math.floor(order?.value?.products?.[0]?.discount_percent || 0);
    return originDiscount || productDiscount;
  };
  const discountPercent = getDiscountPercent();

  const getShopOrderLink = () => {
    if (order.value.meta.type === 'shopify') {
      return `https://${order.value.origin.domain}.myshopify.com/admin/orders/${order.value.meta.origin_id}`;
    }
  };
  const shopOrderLink = getShopOrderLink();

  return { discountPercent, shopOrderLink };
};
</script>

<style scoped lang="scss">
.order-header {
  .header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    .left {
      .title-container {
        font-size: large;
        font-weight: bold;
        .identifier {
          color: $brand-corail;
        }
      }

      a {
        color: $brand-mint;
        text-decoration: none;
      }
    }

    .right {
      .delete-order {
        background: white;
        border: 1px solid $brand-deep-purple;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .header-bottom {
    .line-top {
      margin-bottom: 5px;
    }

    .line-top,
    .line-bottom {
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: row;
      > * {
        margin-right: 15px;
      }

      b {
        margin-right: 5px;
      }
    }

    .date,
    .order-total,
    .shipping-fee {
      display: flex;
    }
  }
}
</style>
