<template>
  <header></header>
  <MainNavigation :hasOrderNav="hasOrderNav" :orderCount="count" />
  <OrderNavigation v-if="hasOrderNav" :orders="getSortedOrders" :orderCount="count" />
  <main>
    <router-view :key="$route.fullPath" />
  </main>
</template>

<script setup>
import { onMounted, provide, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useOrdersStore } from '../stores/orders.js';
import MainNavigation from '../components/Shared/Layout/MainNavigation.vue';
import OrderNavigation from '../components/Shared/Layout/OrderNavigation.vue';

const ordersStore = useOrdersStore();
const route = useRoute();
const routeName = computed(() => route?.name?.toLocaleLowerCase());
const hasOrderNav = computed(() => routeName.value === 'order');
const websocket = ref(null);
const { initWebsocket } = useInit();
const { listenOrders } = useOrders();

const { getSortedOrders, count } = storeToRefs(ordersStore);

provide('websocket', websocket);

onMounted(async () => {
  try {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt) {
      await ordersStore.fetch();
      await ordersStore.fetchCount();
      websocket.value = await initWebsocket(jwt);
      listenOrders(websocket.value);
    }
  } catch (e) {
    console.error(e);
  }
});
</script>

<script>
const useInit = () => {
  const initWebsocket = (jwt) =>
    new Promise((resolve, reject) => {
      const wsHost = new URL(process.env.VUE_APP_WEBSOCKET_SERVER_HOST).host;
      const wsUrl = `ws://${wsHost}/websocket?jwt=${jwt}`;
      const ws = new WebSocket(wsUrl);
      ws.onopen = () => {
        console.log(`websocket open on ws://${wsHost}/websocket`);
        resolve(ws);
      };

      ws.onclose = (e) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('websocket closed', e);
        }
        setTimeout(() => initWebsocket(), 500);
        reject();
      };

      ws.onerror = function (e) {
        console.error('websocket error: ', e.message, 'closing websocket');
        ws.close();
      };
    });

  return { initWebsocket };
};

const useOrders = () => {
  const orderStore = useOrdersStore();
  // listen to orders changes via websocket
  const listenOrders = (websocket) => {
    websocket.onmessage = (event) => {
      const { operation, ...payload } = JSON.parse(event.data);
      if (process.env.NODE_ENV === 'development') {
        console.log('received websocket for orders', { operation, payload });
      }
      if (operation === 'insertOrder') {
        const { toInsert } = payload;
        orderStore.addOrder(toInsert);
      } else if (operation === 'deleteOrder') {
        const { orderId, orderOriginId } = payload;
        let toDeleteIndex = -1;
        if (orderId) {
          // toDeleteIndex = orders.value.findIndex((order) => order._id === orderId);
        } else if (orderOriginId) {
          // toDeleteIndex = orders.value.findIndex((order) => order.meta.origin_id === orderOriginId);
        }
        if (toDeleteIndex > -1) {
          // orders.value.splice(toDeleteIndex, 1);
        }
      }
    };
  };
  return { listenOrders };
};
</script>

<style lang="scss"></style>
